
import { configureStore } from "@reduxjs/toolkit";
import UserReducer  from "../slice/user/UserSlice";

import UserProjectReducer from "../slice/user/UserProject"

import LoadingReducer from "../slice/loading/LoadingSlice"

import ApiModelDataReducer from "../slice/modelData/ModelDataSlice"

import MaterialReducer from "../slice/materialSlice/MaterialSlice"
import MaterialBrandReducer from "../slice/materialSlice/MaterialBrandSlice"
import MaterialcategoryReducer from "../slice/materialSlice/MaterialCategorySlice"
import MaterialStyleReducer from "../slice/materialSlice/MaterialStyleSlice"

import UserJobSlice from "../slice/userJobSlice/UserJobSlice";
import JobSlice from "../slice/Admin/JobSlice/JobSlice";
import userScreenResolution from "../slice/userScreenSlice/userScreenResolution";

import SegmentSlice from "../slice/segment/SegmentSlice";
import MasterArraySlice from "../slice/canvas/masterArray/MasterArraySlice";

import TabSlice from "../slice/tabControl/TabControlSlice"
import MediaSlice from "../slice/media/MediaSlice"


import SvgImageSlice from "../slice/svdImageSlice/SvgImageSlice";
import DimensionSlice from "../slice/canvas/masterArray/DimensionSlice";

import GroupSlice from "../slice/canvas/groupSlice.tsx/GroupSlice"
import CanvasControlSlice from "../slice/canvas/masterArray/CanvasControlSlice";

import ToastSlice from "../slice/messageToast/ToastSlice";

import SwatchOnCanvasSlice from "../slice/canvas/masterArray/SwatchOnCanvas";
import WebSocketSlice from "../slice/webSocketSlice/WebSocketSlice";

import GenAiAttributesSlice from "../slice/genAi/GenAiAttributesSlice";

import UpdateValueSlice from "../slice/canvas/updatevalue/UpdateValueSlice";
import SegregatedSlice from "../slice/segegratedSlice/SegregatedSlice";
import ReplaceSwatchSlice from "../slice/replaceSwatch/ReplaceSwatchSlice";
export default configureStore({


    reducer: {
        user:UserReducer,
        userProjects:UserProjectReducer,
        userJob:UserJobSlice,
        canvasDimension:DimensionSlice,
        segment:SegmentSlice,
        masterArray:MasterArraySlice,
        segregatedSegment:SegregatedSlice,
        svgImage:SvgImageSlice,
        groupSlice:GroupSlice,
        swatchOnCanvas:SwatchOnCanvasSlice,
        canvasControl:CanvasControlSlice,
        genai_attributes:GenAiAttributesSlice,
        replaceSwatch:ReplaceSwatchSlice,
        toastSlice:ToastSlice,
        loading:LoadingReducer,
        webSocket:WebSocketSlice,
        apiModel:ApiModelDataReducer ,// API model data

        material: MaterialReducer,
        materialBrand: MaterialBrandReducer,
        materialCategory: MaterialcategoryReducer,
        materialStyle: MaterialStyleReducer,

        jobs:JobSlice,

        windowResolution:userScreenResolution,
       

       
        tabControl:TabSlice,
 
        media:MediaSlice,
        updateValue:UpdateValueSlice,
     
      

    }
}
)