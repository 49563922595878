import React from 'react'
import "./layout.scss";
import { Outlet, Link } from 'react-router-dom';
import Header from '../module/header/Header';
import Footer from '../module/footer/Footer';
import LeftSection from '../module/leftSection/LeftSection';
import { UseDispatch, useSelector } from 'react-redux'
import { getUserLogin } from '../slice/user/UserSlice';
const Layout = () => {

  const getUserLogins= useSelector(getUserLogin)
  return (
    <div className='main-body-wrapper'>
        {/* <Header/> */}
          <main>
           
            <Outlet/>
          </main>
        <Footer/>
    </div>
  )
}

export default Layout