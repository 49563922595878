
import React,{useEffect, useState, useRef} from 'react'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Checkbox, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import Unitque from './Unitque'
import Action from './action/Action'
import "./EachSeg.scss";
import PalletInformation from './PalletInformation';
import { GroupMasterSegment, JobSegmentationModel, MasterArrayModel } from '../../../../Model/masterArray/MasterArrayModel';
import { useDispatch, useSelector } from 'react-redux';
import { getMasterArray, startSegHoverEffect, stopSegHoverEffect } from '../../../../slice/canvas/masterArray/MasterArraySlice';


import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import 'swiper/swiper-bundle.css';

import '../LeftSections.scss';
import SwatchImages from '../selectSwatch/SwatchImages';
import { SwatchesModel } from '../../../../Model/material/MaterialModel';
import { useSelect } from '@react-three/drei';
import { getAllSwatches } from '../../../../slice/materialSlice/MaterialSlice';
import { addAllSwatchImages, addSelectedSegment } from '../../../../slice/userJobSlice/UserJobSlice';
import GroupSegment from './GroupSegment';
import { addSegment } from '../../../../slice/canvas/groupSlice.tsx/GroupSlice';
import { getCanvasControl } from '../../../../slice/canvas/masterArray/CanvasControlSlice';
import CurrentSelectedSwatch from '../currentSelectSwatch/CurrentSelectedSwatch';




type Props={
  eachSegment:MasterArrayModel
  masterSegName:string   // groupName
}
const EachSeg = ({eachSegment,masterSegName}:Props) => {
  const [seg, setSeg]= useState<GroupMasterSegment[]>([])
   const dispatch= useDispatch()
   const [ allSwatchImages, setAllSwatchImage]= useState<SwatchesModel[]>([])
 const[masterSegNames, setMasterSegName]= useState<string|null>(null)
   const getAllSwatchess= useSelector(getAllSwatches)
   const [activeIndex, setActiveIndex] = useState<number | null>(null);
   const [activeTab, setActiveTab] = useState(0);
   const swiperRef = useRef<SwiperClass | null>(null);
   const [ eachSeg, setEachSeg]= useState<string|null>(null)
   const getMasterArrays = useSelector(getMasterArray);
    
     const getCanvasControls= useSelector(getCanvasControl)
         
     
     useEffect(()=>{

          if(eachSegment && eachSegment.segmentation && 
            eachSegment.segmentation.length>0 && 
            eachSegment.name){
           
            setMasterSegName(eachSegment.name)
             const allSeg:GroupMasterSegment[]=[]
             eachSegment.segmentation.map(item=>{
                   Object.keys(item).map(key=>{
                    allSeg.push({
                      [key]:item[key]
                    })
                   })
             })
             if(allSeg.length>0){
              setSeg(allSeg)
             }
          }
         },[eachSegment])



  // show first swatch
  const isApiFirst= useRef(true)
  useEffect(()=>{
    if(seg  &&
       seg.length>0  && 
       isApiFirst.current){
      isApiFirst.current= false
          const firstObject = seg[0]; 
    const firstKey = Object.keys(firstObject)[0];
    const firstValue = firstObject[firstKey];  
          setEachSeg(firstKey)
         
 dispatch(addSelectedSegment(firstValue))
     
    }
     else if (getCanvasControls.subGroupName && 
      seg  && seg.length>0 ){
        const selectedSubgroupIndex= seg.findIndex((item, index)=>
           Object.keys(item).map(grp=>{
              if(grp===getCanvasControls.subGroupName){
                return index
              }
           })
        )

        if(selectedSubgroupIndex!=-1){
         
          setEachSeg(getCanvasControls.subGroupName)
        const chidvalue= seg[selectedSubgroupIndex][getCanvasControls.subGroupName]
       
        dispatch(addSelectedSegment(chidvalue))
        }
      }
  },[seg, getCanvasControls])

  // getAll swatch image based on Segments
//   useEffect(()=>{
//     console.log("getAllSwatchess",getAllSwatchess)
//        console.log("masterSegName",masterSegName)
//     if( masterSegName &&
//          getAllSwatchess &&
//          getAllSwatchess.length>0
//         ){
//           console.log("getAllSwatchess",getAllSwatchess)
//             if(masterSegName==="Window"){
//                 const searchSeg=["Windows","Vents",]
//                 getSwatch(searchSeg)
//                 // masterSegName="Windows"
//             }
//             else if (masterSegName==="Roof"){
//                 const searchSeg=["Roofing ", "Tile","Paint"]
//                 getSwatch(searchSeg)
//             }
//             else if (masterSegName==="Wall"){
//                 const searchSeg=["Brick","Siding","Wall Panels","Pediment","EIFS","Stone","Stain"]
//                 // const searchSeg=["Paint","Siding","Brick","Stone"]
//                 getSwatch(searchSeg)
//            }
//             else if (masterSegName==="Trim"){
//                 const searchSeg=["Paint", "Pediment", "Soffit"]
//                 getSwatch(searchSeg)
//            }
//             else if (masterSegName==="Door"){
//                 const searchSeg=["Doors","Gate"]
//                 getSwatch(searchSeg)
//            } else if (masterSegName==="GarageDoor"){
//             const searchSeg=["Garage Doors","Gate", "Paint"]
//             getSwatch(searchSeg)
//        }
//            else if (masterSegName==="Gutter"){
//             const searchSeg=["Gutters"]
//             getSwatch(searchSeg)
//        }

           
          
//         }
// },[getAllSwatchess,masterSegName])



// const getSwatch=(searchSeg:string[])=>{
//   const swat:SwatchesModel[]=[]

//   searchSeg.map(items=>{
//     const allSwatch:SwatchesModel[]= getAllSwatchess.filter(item=>
//         item.style_brand_category?.brand?.category?.title===items
//     )
//     swat.push(...allSwatch)
//   })
  
 
// if (swat.length > 0) {
// //   const sortedSwatches = swat.sort((a, b) => (b.is_featured ? 1 : -1));
// // setAllSwatchImage(sortedSwatches);
//    dispatch(addAllSwatchImages(swat))
// }

// }


  // on Hover segment
    const handleHoverSegment=(data:JobSegmentationModel[])=>{
      
      if(seg){
       
            dispatch(startSegHoverEffect({
                seg:data 
               }))
         
        
      }
    
    }
    const handleOffHoverSegment=()=>{
      
      dispatch(stopSegHoverEffect())
    }


    
   
    const handleTabClick = (index:number) => {
        setActiveTab(index);
        if (swiperRef.current) {
        swiperRef.current?.slideTo(index);
        }
    };

    const handleEachSegment=(eachKey:string, index:number,childValue:JobSegmentationModel[])=>{
      setEachSeg(eachKey)
      dispatch(addSelectedSegment(childValue))
             if(seg){
              const keyValue= Object.keys(seg).map(item=>{
                // if(item===eachKey){
                //  setEachSeg(item||"")
                //  dispatch(addSelectedSegment(seg[item]))
                // }

           })
             }
      
      setActiveIndex(index)
    
      
    }

    // handle add segment
    const handleAddSegment=()=>{
        
        dispatch(addSegment({
          isOpenModel:true,
          groupName:eachSegment.name
        }))
    }

  return (
<>
  
<div className='segment-tab-menu'>
<div className='seg-tab-menu-list'>
  <Swiper
    spaceBetween={0}
    slidesPerView={'auto'}
    freeMode={true}
    navigation={{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }}
    onSlideChange={() => console.log('slide change')}
    onSwiper={(swiper) => console.log(swiper)}
  >
    {seg && seg.length > 0 && seg.map((grpSeg, index) => (
      Object.keys(grpSeg).map((item) => {
        const segment = item; // Get the segment data
        const childValue=grpSeg[item]
        return (
          <SwiperSlide 
            style={{
              width: 'auto',
              backgroundColor: eachSeg === item ? 'lightblue' : 'white' // Compare with the key `item`
            }}
            key={item}
            onMouseEnter={() => handleHoverSegment(childValue)} 
             onMouseLeave={handleOffHoverSegment}
           onClick={() => handleEachSegment(item, index,childValue)} 
          >
            {segment}
          </SwiperSlide>
        );
      })
    ))}
  </Swiper>
  <div className='house-icon' onClick={handleAddSegment}>
    <i className="bi bi-house-add "></i>
  </div>
  </div>
 
</div>


          
    {seg &&
    seg.length>0 ? (
      seg.map(childgrp=>{
        return(
          Object.keys(childgrp).map(item=>{
             
            if(eachSeg===item){
        const childValue=childgrp[item]
              return(
                <div className='m-0'>
                    
                      <GroupSegment
                      allSegDetail={childValue}
                      groupName={eachSeg}
                      />
                    
  
                   
                </div>
              )
            }
            
          })
        )
      })
    ):(<>
    No segment 
    </>)
   
      
     
    }
          

  
          <div className='segmentview-details'>
                      {/* <div className='selected-items p-2'>
                          <h6>Current Selected Area</h6>
                          <div className='row g-2'>
                              <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette1.jpg" alt="" />
                                  </div>
                              </div>
                              <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette3.jpg" alt="" />
                                  </div>
                              </div>
                              <div className='col-2'>
                                  <div className='palletsbox active'>
                                      <img src="/img/palette3.jpg" alt="" />
                                      <svg className="checkicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
                                          <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                                      </svg>
                                  </div>
                              </div>
                              <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div>
                              <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div>
                              <div className='col-2'>
                                  <div className='palletsbox'>
                                      <img src="/img/palette4.jpg" alt="" />
                                  </div>
                              </div>
                          </div>
                      </div> */}
            
            <CurrentSelectedSwatch
            groupName={masterSegName}
            />



                      <div className="tab-content">
                          <div className="tab-pane active" id={`${eachSeg}01`} role="tabpanel" aria-labelledby={`${eachSeg}01`}>
                              <div className="tabs-navigationdd">
                                  <button
                                      className={activeTab === 0 ? 'active' : ''}
                                      onClick={() => handleTabClick(0)}
                                      >
                                      Brand
                                  </button>
                                  <button
                                      className={activeTab === 1 ? 'active' : ''}
                                      onClick={() => handleTabClick(1)}
                                      >
                                      Style
                                  </button>
                                  <button
                                      className={activeTab === 2 ? 'active' : ''}
                                      onClick={() => handleTabClick(2)}
                                      >
                                      Tab 3
                                  </button>
                              </div>

                              <Swiper
                                  onSwiper={(swiper) => (swiperRef.current = swiper)}
                                  spaceBetween={50}
                                  slidesPerView={1}
                                  initialSlide={activeTab}
                                  onSlideChange={(swiper) => setActiveTab(swiper.activeIndex)}
                              >
                                  <SwiperSlide>
                                      <div className='p-3 swichpallets-box'>
                                          <div className='row g-3'>
                                              <SwatchImages
                                              //allSegDetail={seg[item]}
                                              />
                                          </div>
                                      </div>
                                  </SwiperSlide>
                                  <SwiperSlide>dsfdsf</SwiperSlide>
                                  <SwiperSlide>hhhhh</SwiperSlide>
                              </Swiper>
                          </div>
                          <div className="tab-pane" id={`${eachSeg}02`} role="tabpanel" aria-labelledby={`${eachSeg}02`}>mmm</div>
                          <div className="tab-pane" id={`${eachSeg}03`} role="tabpanel" aria-labelledby={`${eachSeg}03`}>hhh</div>
                          <div className="tab-pane" id={`${eachSeg}04`} role="tabpanel" aria-labelledby={`${eachSeg}04`}>kkk</div>
                          <div className="tab-pane" id={`${eachSeg}05`} role="tabpanel" aria-labelledby={`${eachSeg}05`}>kkk</div>
                      </div>
                  </div>
 
   
</>

  )
}

export default EachSeg


