import React, { useEffect, useState } from 'react'
import "./RightSection.css"
import Tools from './tools/Tools'
import MainCanvas from './MainCanvas'
import RegenerationHome from '../../regeneration/RegenerationHome'
import { useSelector } from 'react-redux'
import { getIsRegenaration } from '../../../slice/modelData/ModelDataSlice'

const RightSection = () => {
    const[isRegeneration, setIsRegeneration]= useState<boolean>(false)

      const getIsRegenarations= useSelector(getIsRegenaration)

  useEffect(()=>{
    if(getIsRegenarations){
      
      setIsRegeneration(getIsRegenarations)
    }else if(!getIsRegenarations){
      setIsRegeneration(getIsRegenarations)
    }
  },[getIsRegenarations])
   

  
  // getCanvasMode

  return (
    // <div className='right-project-layer-img mt-0 h-100'>
   <>


 <MainCanvas/>


{ isRegeneration &&
 <RegenerationHome/>}

  {/* <div className='select-toolbar-section'> */}

  {/* </div> */}
   </>
    


    // </div>
  )
}

export default RightSection
