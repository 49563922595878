
import React, { useState } from 'react'
import "./Tools.css";
import { useDispatch } from 'react-redux';
import { addRegeneration } from '../../../../slice/modelData/ModelDataSlice';
import { changeSegmentTab, startDownload, switchToOutline } from '../../../../slice/tabControl/TabControlSlice';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { startDownlaod } from '../../../../slice/canvas/updatevalue/UpdateValueSlice';
import { startUpdateJob } from '../../../../slice/canvas/masterArray/MasterArraySlice';

type Props = {

}


const Tools = () => {
  const [showicon, setshowicon] = useState(true);
  const [isProjectLayer, setIsProjectLayer] = useState(true);
  const [isNoiseReduction, setIsNoiseReduction] = useState(false);
  const [isOutLine, setIsOutLine] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [isDimension, setIsDimension] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [isDownloadImage, setIsDownloadImage] = useState(false);
  const [isFinalLayer, setIsFinalLayer] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleShow = () => {
    setshowicon(!showicon)
  }


  const handleRegeneration = () => {
    dispatch(addRegeneration(true))
  }

  const handleOutline = () => {
    dispatch(switchToOutline("outline"))
    setIsOutLine(true)
    setIsProjectLayer(false)
    setIsCompare(false)
    setIsDimension(false)
  }

  const handleHoverLayer = () => {
    dispatch(switchToOutline("segment"))
    setIsOutLine(false)
    setIsCompare(false)
    setIsProjectLayer(true)
    setIsDimension(false)
  }


  const handleSwitchChange = () => {
    dispatch(switchToOutline("compare"))
    setIsOutLine(false)
    setIsProjectLayer(false)
    setIsCompare(true)
    setIsDimension(false)
  }

  const handleDimension = () => {
    dispatch(switchToOutline("dimension"))
    setIsDimension(true)
    setIsOutLine(false)
    setIsProjectLayer(false)
    setIsCompare(false)

  }


   const captureScreenshot=()=>{
    console.log("screen start")
      dispatch(startDownlaod())
   }

  
   const handleSaveToDB=()=>{
           dispatch(startUpdateJob())

   }

  return (
    <div className="select-pallet-toolbars">

    

      <div className='tool-show-hide-icon' onClick={handleShow}>
        <i className="bi bi-eye-fill"></i>

      </div>



      {showicon &&

        <div className='pro-tool-wrapper-icons'>
          <div className="tool-wrapper">


            <div className="select-tool-project">

            
               <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Canvas Mode</Tooltip>}> 
                
                <div className={isProjectLayer ? "active-icon" : ""}
                
                >
                  <i
                    className="bi bi-intersect"
                    onClick={handleHoverLayer}
                  ></i>
                </div>
                </OverlayTrigger>

               <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Outlines</Tooltip>}>
                <div className={isOutLine ? "active-icon" : ""}>
                  <i className="bi bi-border"
                    onClick={handleOutline}
                  ></i>
                </div>
                </OverlayTrigger>

              
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Compare</Tooltip>}>
                <div className={isCompare ? "active-icon" : ""}>
                  <i
                    className="bi bi-chevron-bar-contract"
                    onClick={handleSwitchChange}
                  ></i>
                </div>
                </OverlayTrigger>

            


              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Dimention</Tooltip>}>
                <div className={isDimension ? "active-icon" : ""}
                  onClick={handleDimension}
                >
                  <i className="bi bi-rulers"></i>
                </div>
                </OverlayTrigger>


            
             

            
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Regeneration</Tooltip>}>

                <div
                  onClick={handleRegeneration}
                  className={isCompare ? "active-icon" : ""}>
                  <i className="bi bi-repeat"></i>
                </div>
                </OverlayTrigger>


              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Save</Tooltip>}>

                <div className='save-icons' 
                onClick={handleSaveToDB}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy" viewBox="0 0 16 16">
                    <path d="M11 2H9v3h2z" />
                    <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                  </svg>
                </div>
                </OverlayTrigger>

            
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Download Image</Tooltip>}>
                
                <div className={isDownloadImage ? "active-icon" : ""}
                  onClick={captureScreenshot}>
                  <i className="bi bi-download" ></i>
                
                </div>
                </OverlayTrigger>


            </div>


            <div className="select-tool-project">

           
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Undo</Tooltip>}>

                <div className={editActive ? "is-edit-active" : ""}>
                  <i className="bi bi-arrow-left-square"></i>
                </div>
                </OverlayTrigger>

          
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Save Combination</Tooltip>}>

                <div >
                  <i className="bi bi-back"  ></i>
                  {/* onClick={handleCombination} */}
                </div>
                </OverlayTrigger>

        
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Final Layer</Tooltip>}>

                <div className={isFinalLayer ? "active-icon" : ""}>
                  <i className="bi bi-layers-fill"></i>
                  {/* onClick={handleFinalLayer} */}
                </div>
                </OverlayTrigger>

            

             
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Report</Tooltip>}>

                <div className={isCompare ? "active-icon" : ""}>
                  <i className="bi bi-file-earmark-ppt" ></i>
                  {/* onClick={handleReport} */}
                </div>
                </OverlayTrigger>

          
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Information</Tooltip>}>

                <div className={isCompare ? "active-icon" : ""}>
                  <i className="bi bi-info-lg" ></i>
                  {/* onClick={handleTesting} */}
                  {/* onClick={onOpen} */}
                </div>
                </OverlayTrigger>



              
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Conneted Shape</Tooltip>}>

                <div
                  // onClick={handleOverLap}
                  className={isCompare ? "active-icon" : ""}>

                  <i className="bi bi-columns"></i>
                </div>
                </OverlayTrigger>

            
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Door height</Tooltip>}>
                <div
                  // onClick={handleDoorSize}
                  className={isCompare ? "active-icon" : ""}>
                  <i className="bi bi-door-closed-fill"></i>
                </div>
                </OverlayTrigger>

       
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Full Screen</Tooltip>}>
                <div >
                  <i className="bi bi-arrows-fullscreen"
                  //    onClick={handleNewDesign}
                  ></i>
                </div>
                </OverlayTrigger>

            

          
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Noise Reduction</Tooltip>}>

                <div className={isNoiseReduction ? "active-icon" : ""}>
                  <i className="bi bi-eraser"
                  // onClick={handleNoise}
                  ></i>
                </div>
                </OverlayTrigger>

            </div>

          </div>
        </div>
      }

    </div>

  )
}

export default Tools
