import React, { useEffect, useRef } from 'react'
import { useState } from 'react';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';

import "./AddGroupModel.scss";
import { getGroupList, updateChildAddSegment, updateGroup } from '../../../../slice/canvas/groupSlice.tsx/GroupSlice';
import { useNavigate } from 'react-router-dom';
import { MainGroupModel } from '../../../../Model/groupingModel/GroupingModel';
import { addGroupOnSegmentation, getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice';
import { changeSegmentTab } from '../../../../slice/tabControl/TabControlSlice';

type Props={
    isSegmentModal:boolean
    segmentGroupName:string
    handleClose:()=>void
}
const AddGroupModel = ({isSegmentModal,handleClose,segmentGroupName}:Props) => {
    const getGroupLists= useSelector(getGroupList)
     const  navigate= useNavigate()
     const dispatch= useDispatch()
    const[groupList, setGroupList]= useState<MainGroupModel|null>(null)
    const getMasterArrays= useSelector(getMasterArray)
      const [jobId, setJobId]= useState<number|null>(null)
   const [groupChildName, setGroupChildName]= useState<string|null>(null)
    
   // get job id
   useEffect(()=>{
        if(getMasterArrays && getMasterArrays.jobId){
            setJobId(getMasterArrays.jobId)
        }
     },[getMasterArrays])

// get the group Main group
const isgroup= useRef(true)
     useEffect(()=>{
        if(getGroupLists && 
          getGroupLists.length>0 && isgroup.current){
            isgroup.current= false
         const group=   getGroupLists.find(item=>item.title==segmentGroupName)
             if(group) {
         setGroupList(group)
            console.log("group", group)
            }
        }
     },[getGroupLists])




     // new SubGroup
     const handleNewChildGroup=()=>{
      
          if(groupList && groupList.groups && groupList.groups.length>0){
             const name:string= groupList.title??""
             
            const newSubGroup=(name+(groupList.groups.length +1))
            console.log("newSubGroup",newSubGroup)
                if(newSubGroup){
                  //add group in groupslice
                dispatch(updateGroup({
                  group: name,
                  groupName: newSubGroup
                }))

                  // add group in  master array
            dispatch(addGroupOnSegmentation({
              segmentGroupName: name,
              groupName: newSubGroup
            }))
          }
          setGroupChildName(newSubGroup)
                }
            
     }


     // add child segment
     const handleChildGroup=(groupName:string, childGroupName:string)=>{
        
            setGroupChildName(childGroupName)
            
     }


     const handleChangeCanvas=()=>{
      console.log("groupChildName",groupChildName)
      isgroup.current= true
      if(groupChildName==null){
        alert("Please select segment group")
       
      }else{
        dispatch(updateChildAddSegment(groupChildName))
        setGroupChildName(null)
        dispatch(changeSegmentTab('newSegment'));
        // navigate(`/project/new-segment/${jobId}`)
      }
      
     }
  return (
    <>
         <Modal
        show={isSegmentModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header  closeButton>
         <h5 className='model-title'>Add Segment</h5>
        </Modal.Header>
        <Modal.Body>
        <Form>
      
        <div className="py-2 add-seg">
            <>
            <Form.Check
                    inline
                    label="Add New Group"
                    name="group1"
                    type='radio'
                    className='fs-5' 
                    onClick={handleNewChildGroup}
                   
                  />
                   { groupList  &&
         groupList.groups &&
         groupList?.title &&
         groupList.groups.length>0 ?(
            groupList.groups.map(item=>{
                return(
                    <>
                       <Form.Check
                    inline
                    label={`${item.title}`}
                    name="group1"
                    type='radio'
                    className='fs-5'
                    onClick={()=>handleChildGroup(groupList?.title??"", item.title??"")}
                   
                  />
                   
                    </>
                 
                )
            })
         ):(<>
            <p className='pt-2'> No child group avaiable</p>
         </>)

      }
            </>
        
       
        </div>
     
    </Form>
        </Modal.Body>
        <Modal.Footer>
        <button className='btn btn-tranparent border-primary text-primary'  onClick={handleClose}>Close</button>
        <button className='btn btn-primary' onClick={handleChangeCanvas}>Ok</button>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button> */}
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddGroupModel