import React, { useEffect, useState } from 'react'
import HoverLayer from './HoverLayer/HoverLayer'
import ImageUrlBase64 from './HoverLayer/ImageUrlBase64'
import CreateMasterArray from '../../createMasterArray/CreateMasterArray'
import GetScreenResolution from '../../getUserScreenResolution/GetUserScreenResolution'
import SegegrateSegementBaseonPixel from '../../projectPage/jobs/SegegrateSegementBaseonPixel'
import Information from '../LeftSections/EachSegments/action/Information'
import { useDispatch, useSelector } from 'react-redux'
// import { getSegmentInfo, resetInformation, } from '../../../slice/userJobSlice/UserJobSlice'
import { getMasterArray, updateAnnotationPoint } from '../../../slice/canvas/masterArray/MasterArraySlice'
import Loading from '../../loading/Loading'
import { getTabControl } from '../../../slice/tabControl/TabControlSlice'
import OutLineCanvas from '../../canavas/outLineCanvas/OutLineCanvas'
import CompareHome from '../../canavas/compare/CompareHome'
import Dimensioncanvas from '../../canavas/dimension/Dimensioncanvas'
import DimensionCalculate from '../../canavas/dimension/DimensionCalculate'
import GetGenAiImage from '../genAi/GetGenAiImage'
import { getAddSegmentGroup } from '../../../slice/canvas/groupSlice.tsx/GroupSlice'
import AddNewSegmentHome from '../../canavas/newSegment/addNewSegment/AddNewSegmentHome'
import GenAiHome from '../genAi/GenAiHome'
import GenAiCanvasHome from '../genAi/genApiCanvas/GenAiCanvasHome'
import Tools from './tools/Tools'
import MessageToast from '../../messageToast/MesageToast'
import { getToast } from '../../../slice/messageToast/ToastSlice'
import DeleteSegmentModal from '../LeftSections/deleteSegment/DeleteModal'
import { getDeleteModalStatus, getDeleteStatus, openCloseDeleteSegment, startDeletingSegment, stopDeletingSegment } from '../../../slice/canvas/masterArray/CanvasControlSlice'
import UpdateBase64Db from '../updateJob/UpdateBase64Db'
import GetJobHome from '../../projectPage/GetJobHome'
import NewSegmentCanvas from '../../canavas/newSegment/newSegRight/NewSegmentCanvas'
import { StartUpateMasterArrayModel } from '../../../Model/masterArray/UpdateIndexModel'
import ReAnnotationCanvas from '../../canavas/reannotationCanvas/reAnnotationRight/ReAnnotationCanvas'
import { getNewSegment, getSegregateSegment, getStartMaterArrayUpdate, resetStartMasterArrayStart } from '../../../slice/segegratedSlice/SegregatedSlice'
import UpdateAllIndexing from '../LeftSections/deleteSegment/UpdateAllIndexing'
import EditAnnoatationCanvas from '../../canavas/editAnnotation/EditAnnoatationCanvas'
import AddSegmentHome from '../LeftSections/segments/AddSegmentHome'
// import OutLineCanvas from './outLineCanvas/OutLineCanvas'




const MainCanvas = () => {
  const [isInfo, setIsInfo]= useState<boolean>(false)
   const dispatch= useDispatch()
  // const getSegmentInfos= useSelector(getSegmentInfo)
  // useEffect(()=>{
  //  if(getSegmentInfos && getSegmentInfos.isInfo && getSegmentInfos.segment){
  //   setIsInfo(getSegmentInfos.isInfo)
  //  }else {
  //   setIsInfo(false)
  //  }
  // },[getSegmentInfos])
  // const handleCloseInformation=()=>{
  //   setIsInfo(false)
  //   dispatch(resetInformation())
  // }

  const getMasterArrays = useSelector(getMasterArray)

  
  const getSegregateSegments= useSelector(getSegregateSegment)
  const [isCreateMasterArray , setIsCreatemaster]= useState<boolean>(false)
  const [isSegregatedGEment , setIsSegregatedGEment]= useState<boolean>(false)


  useEffect(()=>{
    
    if(getMasterArrays && getMasterArrays.jobId){
      setIsCreatemaster(false)
      
    }else {
      
      setIsCreatemaster(true)
    }
  },[getMasterArrays])


  useEffect(()=>{
    if(getSegregateSegments &&getSegregateSegments.length==0){

      setIsSegregatedGEment(true)
      
    }else{
      
      setIsSegregatedGEment(false)
    }
  },[getSegregateSegments])


  // get control outline canavas
  const getTabControls= useSelector(getTabControl)
  const [ canavasMode, setCanvasMode]= useState<string|null>(null)
  useEffect(()=>{
    if(getTabControls){
      setCanvasMode(getTabControls)
    }
  },[getTabControls])

 
     // delete segment modal
   const[ isDelete, setIsDelete]= useState<boolean>(false)
     const getDeleteModalStatuss= useSelector(getDeleteModalStatus)


     useEffect(()=>{
      if(getDeleteModalStatuss){
        setIsDelete(true)
      }else{
        setIsDelete(false)
      }
     },[getDeleteModalStatuss])


     const handleCloseModal=()=>{
       dispatch(openCloseDeleteSegment(false))
       setIsDelete(false)
     }

     const handleOkdelete=()=>{
      dispatch(openCloseDeleteSegment(false))
       dispatch(startDeletingSegment())
       setIsDelete(false)
     }

      /* callMaster Array slice to update points */
      const getStartMaterArrayUpdates= useSelector(getStartMaterArrayUpdate)
      useEffect(()=>{
        if(getStartMaterArrayUpdates && getStartMaterArrayUpdates.isUpdate){
          
          const data:StartUpateMasterArrayModel={
            segName:getStartMaterArrayUpdates.segName,
            childgroupName:getStartMaterArrayUpdates.childgroupName,
            seg_short:getStartMaterArrayUpdates.seg_short,
            groupName:getStartMaterArrayUpdates.groupName,
            masterData:getStartMaterArrayUpdates.masterData
           
           } 
          // console.log("to upidate master array", data)    
                 dispatch(resetStartMasterArrayStart())
           // update masetr array
           dispatch(updateAnnotationPoint(data))
        }
      },[getStartMaterArrayUpdates, ])

  return (
    <>
    {/* {isInfo &&
    <Information
    isInfo={isInfo}
    handleClose={handleCloseInformation}
    />} */}
     
       
        
          {/* main canvas */}
        { canavasMode=="segment" &&
        <HoverLayer/>}

          {/* new segment canvas */}
       { canavasMode=="newSegment" &&
        <NewSegmentCanvas/>}


          {/* new reAnnotation canvas */}
       { canavasMode=="reAnnotation" &&
        <ReAnnotationCanvas/>}

       { canavasMode=="editAnnotation" &&
        <EditAnnoatationCanvas/>}



          {/* outline canvas */}
        { canavasMode=="outline" &&
          <OutLineCanvas/>}


          {/* compare canvas */}
        { canavasMode=="compare" &&
          <CompareHome/>}


            {/* dimension canvas */}
        { canavasMode=="dimension" &&
          <Dimensioncanvas/>}


            {/* genAi images */}
          { canavasMode=="genAiImage" &&
           <GenAiCanvasHome/>}



            {/*  Tools  */}
        {canavasMode !="genAiImage" &&
        <Tools/>}




        {isCreateMasterArray &&
          <CreateMasterArray/>}

        {/* <GetScreenResolution/> */}

        {/* only run after getting job first time */}
       {/* {isSegregatedGEment && */}
        <SegegrateSegementBaseonPixel/>
        {/* } */}

        {/* get gen Ai images */}
          <GetGenAiImage/>

            {/*  calculate the dimension for all segment */}
     <DimensionCalculate/>
       


          <MessageToast
          />


      {/* open add segment */}
         <AddNewSegmentHome/>

         {/* open delete segment moda; */}

       { isDelete &&
        <DeleteSegmentModal
       isDeleteModal={isDelete}
       okClose={handleCloseModal}
       okDelete={handleOkdelete}
       />}

{/* update base 64 */}
       <UpdateBase64Db/>

       {/* get job from dataBAse and base64 fromApi */}
       <GetJobHome/>

       {/* after delete update all value */}
       <UpdateAllIndexing/>

        {/* Add new segmnt */}
        <AddSegmentHome/>
      
    </>
  )
}

export default MainCanvas