import React, { useEffect, useRef, useState } from 'react';
import { GetGenAiStyle } from '../../../api/genAi/GenAiAPi';
import { useDispatch } from 'react-redux';
import { startLoading } from '../../../slice/loading/LoadingSlice';
import MessageToast from '../../messageToast/MesageToast';
import { addMessage } from '../../../slice/messageToast/ToastSlice';
import StartWebSocket from '../../webSocket/StartWebSocket';
 const backEndUrl=process.env.REACT_APP_BACKEND_URL

type Props = {
  imagePath: string;
};

const WebSocketGenAi = ({ imagePath }: Props) => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const dispatch= useDispatch()
  const lastMessageRef = useRef<string | null>(null);
  const handleStartWebSocket = () => {
   // dispatch(startLoading())
    setIsConnected(true);
  };

  // useEffect(() => {
  //   // Automatically initiate the WebSocket connection when the component mounts
  //   if (!socket) {  // Check if socket is not already initialized
  //     const ws = new WebSocket(`wss://api.dzinly.org/ws`);
  //     console.log("ws ->", ws);

  //     ws.onopen = function () {
  //       console.log("WebSocket connection established.");
  //       const message="WebSocket connection established."
  //       setIsConnected(true); // Set connection state
  //       setError(null); // Reset any previous error
  //       console.log("WebSocket Ready State:", ws.readyState);
  //        dispatch(addMessage({
  //         isShow:true,
  //         mess:message
  //        }))
      
  //     };

  //     ws.onmessage = function (event) {
  //       const triggerMessage=event.data
  //       if (triggerMessage !== lastMessageRef.current) {
  //       dispatch(addMessage({
  //         isShow:true,
  //         mess:triggerMessage
  //        }))
  //       }
  //       lastMessageRef.current = triggerMessage
  //       console.log("Received message event triggered:", event.data);
  //     };

  //     ws.onerror = function (err) {
  //       console.error("WebSocket error:", err);
  //       const message="WebSocket error"
  //       dispatch(addMessage({
  //         isShow:true,
  //         mess:message
  //        }))
  //       setError("WebSocket connection failed."); // Set error state
  //       ws.close(); // Close the connection on error
  //     };

  //     ws.onclose = function () {
  //       console.log("WebSocket connection closed.");
  //        const mess="WebSocket connection closed."
  //       dispatch(addMessage({
  //         isShow:true,
  //         mess:mess
  //        }))
  //       setIsConnected(false);
  //       setSocket(null); // Reset the WebSocket instance
  //       const message="WebSocket connection closed."
        
  //     };

  //     setSocket(ws); // Store WebSocket instance in state
  //   }

  //   // Clean up on component unmount
  //   return () => {
  //     if (socket) {
  //       socket.close(); // Close the WebSocket when the component unmounts or socket is re-established
  //     }
  //   };
  // }, [socket]);  // Only run effect if the socket value changes

  const handleGenAiRequest = async () => {
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      dispatch(addMessage({
        isShow:true,
        mess:"WebSocket is not connected. Please wait or try again.",
         toastType:"success"
       }))
     // alert("WebSocket is not connected. Please wait or try again.")
      console.log("WebSocket is not connected. Please wait or try again.");
      const message="WebSocket is not connected. Please wait or try again."
     
      
    }
    
    fetch(`${backEndUrl}/genai/gen-ai-images`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        url: `${imagePath}`, // Replace with actual image URL
        prompt: [
          "Make this Craftsman style",
          // "Make this Colonial Revival style",
          // "Make this Georgian style",
          // "Make this Tudor style",
          // "Make this Cape Cod style",
          // "Make this French Country style",
          // "Make this Prairie style",
          // "Make this American Foursquare style",
          // "Make this Victorian style",
          // "Make this Shingle style"
        ], // Replace with actual prompt
      }),
    })
    .then(response => response.json())
    .then(data =>{
      console.log("API Response:", data)
    } )
    .catch(error => console.error("Error making API request:", error));

   
  };

  return (
    <div>


  <StartWebSocket/>
      {/* <h1>WebSocket for Gen AI</h1> */}

      <button onClick={handleStartWebSocket}>Start Web Socket</button>

      <button onClick={handleGenAiRequest} disabled={!isConnected}>
        {isConnected ? "Generate AI Images" : "Connecting..."}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>} 
     
    </div>
  );
};

export default WebSocketGenAi;