import React from 'react'
import Seg_Windows from '../../module/segment/Seg_Windows'

const Segment_Window = () => {
  return (
    <div>
      <Seg_Windows/>
    </div>
  )
}

export default Segment_Window
