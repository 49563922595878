import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProjectImage } from '../../../../Model/mediaModel/MediaModel'
import { getGenAiImage } from '../../../../slice/userJobSlice/UserJobSlice'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'


const s3BucketUrl=process.env.REACT_APP_S3Bucket
const GenAiGallery = () => {
    const getGenAiImages= useSelector(getGenAiImage)
    const [genImage, setGenImage]= useState<ProjectImage[]>([])
    const getMasterArrays = useSelector(getMasterArray)
    useEffect(()=>{
        const allImage: ProjectImage[]=[]
        if(getMasterArrays && getMasterArrays.image && getMasterArrays.project_id){
            const data={
                name:"Project Image",
                image:`projects/${getMasterArrays.project_id}/${getMasterArrays.image}`,
                size:0
            }
            allImage.push(data||{})
        }

        if(getGenAiImages &&
             getGenAiImages.length>0){
              const filters=  getGenAiImages.filter(item=>!item.image.includes("canny"))
              
            // setGenImage(filters)
            allImage.push(...filters)
            const cannyItem = getGenAiImages.find(item => item.image.includes("canny"));
            
            if(cannyItem){
                const data2={
                 name:"Canny Image",
                 image:cannyItem.image,
                 size:cannyItem.size,
                 lastModified:cannyItem.lastModified
                }
                allImage.push(data2)
            }
        }

        if(allImage.length>0){
            setGenImage(allImage)
        }
        
    },[getGenAiImages,getMasterArrays])
  return (
    <div  className='gen-ai-image px-4'>
    {
        genImage && 
        genImage.length>0?(
                genImage.map(item=>{
                    
                    const path=`${s3BucketUrl}/${item.image}`
                    let names:string=""
                    if(item.name){
                         names= item?.name.charAt(0).toUpperCase() + item?.name.slice(1).toLowerCase();
                    }
                   
                    return(
                        <>
                        <div>
                       {
                        <h6>{names }</h6>}
                         <img   src={path} alt="no image"  key={item.image}/>
                         </div>
                        </>
                       
                    )
                })
            
        ):
        (<h6> No genai image</h6>)
    }
    
</div>
  )
}

export default GenAiGallery