import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Routes from './routes/Routes';
import 'bootstrap-icons/font/bootstrap-icons.css';
function App() {
  return (
    
    <div className="App">
     <Routes/>
    </div>
  );
}

export default App;
