import React from 'react'
import LeftSection from '../../../leftSection/LeftSection'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import StyleTable from '../../../brandPage/style/StyleTable'
import Header from '../../../header/Header'

const AdminStyleTable = () => {
  return (
    
  <>
  {/* <Header/> */}
    <div className='row'>


<LeftSection
 
/>
<div className="col-9 brand-right-section">

  <StyleTable/>

</div>



{/* getAll materials */}
<GetAllMaterialsHome/>
</div>
  </>
  )
}

export default AdminStyleTable