import React from 'react'

const SamModelPage = () => {
  return (
    <>
        <h6>sam model</h6>
    </>
  )
}

export default SamModelPage