import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Card, Input, InputRef, Table, TableColumnsType } from 'antd';
import Search from 'antd/es/transfer/search';
import { MaterialCategoryModel } from '../../../Model/material/MaterialCategoryModel';
import { getMaterialCategorys, addEditcategory } from '../../../slice/materialSlice/MaterialCategorySlice';
import { getLoading, stopLoading } from '../../../slice/loading/LoadingSlice';
import DelteCategoryApi from '../../admin/AdminMaterial/category/api/Delete CategoryApi';
import CategoryCreateForm from './CategoryCreateFrom' ;
import Loading from '../../loading/Loading';
import "./CategoryTable.css";

const CategoryTable: React.FC = () => {
  const [allCategory, setAllCategory] = useState<MaterialCategoryModel[]>([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getLoadings = useSelector(getLoading);
  const getMaterialCategory = useSelector(getMaterialCategorys) as MaterialCategoryModel[];
  const [isEditProjectForm, setIsProEditForm] = useState(false);
  const navigate = useNavigate();
  const searchInput = useRef<InputRef>(null);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (getMaterialCategory && getMaterialCategory.length > 0) {
      setAllCategory(getMaterialCategory);
    }
  }, [getMaterialCategory]);

  useEffect(() => {
    setIsLoading(getLoadings);
  }, [getLoadings]);

  const handleEditCategory = (record: MaterialCategoryModel) => {
    dispatch(addEditcategory(record));
    navigate(`/dashboard/swatch-categories/${record.id}/edit`);
  };

  const handleDelete = (record: MaterialCategoryModel) => {
    setIsDelete(true);
    setDeleteId(record.id ?? 0);
  };

  const handleResetDelete = (mess: string) => {
    setIsDelete(false);
    setDeleteId(0);
    dispatch(stopLoading());
    alert(mess === "success" ? "Category deleted successfully" : "Error in deleting category");
  };

  const columns: TableColumnsType<MaterialCategoryModel> = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div 
        style={{
          padding: 8,
          width: "300px",
          position: "absolute",
          top: -90,
          zIndex: 1,
        }}
        >
          <Input
            placeholder="Search Id"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}

            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}

            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select());
        }
      },
      onFilter: (value, record) => record.id?.toString().includes(value.toString()) || false,
      filterSearch: true,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div 
        style={{
          padding: 8,
          width: "300px",
          position: "absolute",
          top: -90,
          zIndex: 1,
        }}
        >
          <Input
            ref={searchInput}
            placeholder="Search Title"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}

            // onPressEnter={() => confirm({ closeDropdown: false })}
            onKeyUp={(e) => {
              confirm({ closeDropdown: false });
            }}

            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
        </div>
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 1000);
        }
      },
      onFilter: (value, record) => record.title?.toLowerCase().includes(value.toString().toLowerCase()) || false,
      filterSearch: true,
    },
    {
      title: 'Description ',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Sort Order',
      dataIndex: 'sort_order',
      key: 'sort_order',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: number) => (status === 1 ? 'Active' : 'Inactive'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, record) => (
        <div className="d-flex action">
          <div className="action-icon-table" onClick={() => handleEditCategory(record)}>
            <i className="bi bi-pencil-square mx-0"></i>
          </div>
          <div className="action-icon-table">
            <i className="bi bi-copy mx-0"></i>
          </div>
          <div className="action-icon-table">
          <i className="bi bi-box-arrow-up-right mx-0"></i>
          </div>
          <div className="action-icon-table trash-icon-media" onClick={() => handleDelete(record)}>
            <i className="bi bi-trash3 mx-0"></i>
          </div>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <>
      <div>
        <div className="row mt-3">
          <div className="col-9 brand-title">
            {/* <h1 className="job-title">Category</h1> */}
            <p>{allCategory.length} entries Found</p>
          </div>
          {/* <div className="col-3 breadcrumb-link">
            <Breadcrumb
              items={[
                { title: <a href="">Material Library</a> },
                { title: 'Category' },
              ]}
            />
          </div> */}
        </div>
        <div className="row table-input">
          <div className="col-6 search-bar">
            <Search placeholder="Search categories" />
          </div>
          {/* <div className="col-6">
            <CategoryCreateForm />
          </div> */}
        </div>
        {isLoading && <Loading />}
        <Card className="mb-5 card-table-category">
          {allCategory.length === 0 ? (
            <Loading />
          ) : (
            <Table
              columns={columns}
              dataSource={allCategory.map((item) => ({ ...item, key: item.id }))}
              pagination={{
                position: ['topRight', 'bottomRight'], // Positions pagination at the top and bottom
                defaultPageSize: 200,
                showTotal: (total) => <span className="ant-pagination-total-text">Total <span className='total-page'><i>{total}</i></span> items</span>,
                showSizeChanger: true, // Show page size changer
                pageSizeOptions: ['100', '200', '300', '400', '500', '600', '1000'], // Page size options
              }}
              onChange={handleTableChange}
            />
          )}
        </Card>
        {isDelete && deleteId !== 0 && (
          <DelteCategoryApi deleteId={deleteId} resetDelete={handleResetDelete} />
        )}
      </div>
    </>
  );
};

export default CategoryTable;
