import React, { useState } from 'react';

import { CiPalette, CiIndent, CiViewColumn, CiViewList, CiUser, CiGlobe, CiStop1 } from "react-icons/ci";
import "./_sidebar.scss"
import logo from '../../../../public/logo.svg';
interface SidebarProps {
  toggleActiveClass: () => void; // Define the type for the function prop
}

const Sidebar: React.FC<SidebarProps> = ({ toggleActiveClass }) => {

  // State to track which main menu item is active
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  
  // State to track active submenu item for each parent menu
  const [activeSubmenuIndex, setActiveSubmenuIndex] = useState<{ [key: number]: number | null }>({});

  // Function to handle main menu click
  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };

  // Function to handle submenu click
  const handleSubmenuClick = (parentIndex: number, submenuIndex: number) => {
    setActiveSubmenuIndex((prevState) => ({
      ...prevState,
      [parentIndex]: submenuIndex,
    }));
  };

  
  return (
    <>
        <aside className='sidebar p-3 pe-0'>
          <div className='sidebar-menu border p-3 rounded-4 h-100'>

            <div className="sidebar-header d-flex align-items-center justify-content-between border-bottom mb-3">
              <div className="logo">
                 {/* <img src={logo} alt="Logo" />  */}
                 <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="Logo" />
              </div>
              <button type="button" className="tooglebtn border-0 bg-transparent p-0 lh-1" onClick={toggleActiveClass}>
                <CiIndent />
                </button>
            </div>

            <ul className='sidebar-nav'>
            <li className={activeIndex === 0 ? 'active' : ''} role='button' onClick={() => handleItemClick(0)}>
                  <div className="d-flex align-items-center gap-1">
                    <span className="sidebar-nav-icon"><CiGlobe /></span>
                    <span className="sidebar-nav-text">Dashboard</span>
                  </div>
                  <i className='bx bx-chevron-down dropicon'></i>
                  <ul className='submenu'>
                    <li
                      className={activeSubmenuIndex[0] === 0 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(0, 0)}
                    >Category</li>
                    <li
                      className={activeSubmenuIndex[0] === 1 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(0, 1)}
                    >Brands</li>
                    <li
                      className={activeSubmenuIndex[0] === 2 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(0, 2)}
                    >Style</li>
                    <li
                      className={activeSubmenuIndex[0] === 3 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(0, 3)}
                    >Swatch</li>
                  </ul>
            </li>

                <li className={activeIndex === 1 ? 'active' : ''} role='button' onClick={() => handleItemClick(1)}>
                  <div className="d-flex align-items-center gap-1">
                    <span className="sidebar-nav-icon"><CiPalette /></span>
                    <span className="sidebar-nav-text">Material Library</span>
                  </div>
                  <i className='bx bx-chevron-down dropicon'></i>
                  <ul className='submenu'>
                    <li
                      className={activeSubmenuIndex[1] === 0 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(1, 0)}
                    >Category</li>
                    <li
                      className={activeSubmenuIndex[1] === 1 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(1, 1)}
                    >Brands</li>
                    <li
                      className={activeSubmenuIndex[1] === 2 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(1, 2)}
                    >Style</li>
                    <li
                      className={activeSubmenuIndex[1] === 3 ? 'active' : ''}
                      onClick={() => handleSubmenuClick(1, 3)}
                    >Swatch</li>
                  </ul>
                </li>
                <li className={activeIndex === 3 ? 'active' : ''} role='button' onClick={() => handleItemClick(3)}>
                  <div className="d-flex align-items-center gap-1">
                  <span className="sidebar-nav-icon"><CiViewColumn /></span>
                  <span className="sidebar-nav-text">Project</span>
                  </div>
                </li>
                <li className={activeIndex === 4 ? 'active' : ''} role='button' onClick={() => handleItemClick(4)}>
                  <div className="d-flex align-items-center gap-1">
                  <span className="sidebar-nav-icon"><CiViewList /></span>
                  <span className="sidebar-nav-text">Segments</span>
                  </div>
                </li>
                <li className={activeIndex === 5 ? 'active' : ''} role='button' onClick={() => handleItemClick(5)}>
                  <div className="d-flex align-items-center gap-1">
                  <span className="sidebar-nav-icon"><CiUser /></span>
                  <span className="sidebar-nav-text">Users</span>
                  </div>
                </li>
                <li className={activeIndex === 6 ? 'active' : ''} role='button' onClick={() => handleItemClick(6)}>
                  <div className="d-flex align-items-center gap-1">
                  <span className="sidebar-nav-icon"><CiStop1 /></span>
                  <span className="sidebar-nav-text">Shapes</span>
                  </div>
                </li>
            </ul>

          </div>
        </aside>
    </>
  )
}

export default Sidebar;