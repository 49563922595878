import React from 'react'
import LeftSection from '../../../leftSection/LeftSection'
import GetAllMaterialsHome from '../../../getAllMaterials/GetAllMaterialsHome'
import Swatch from '../../../brandPage/swatch/Swatch'

const AdminSwatchesTables = () => {
  return (
    <div className='row'>


    <LeftSection
     
    />
<div className="col-9 brand-right-section">

<Swatch/>

    </div>

 

    {/* getAll materials */}
    <GetAllMaterialsHome/>
  </div>
  )
}

export default AdminSwatchesTables