import React from 'react'
import Seg_Door from '../../module/segment/Seg_Door'

const Segment_Door = () => {
  return (
    <div>
    <Seg_Door/>
    </div>
  )
}

export default Segment_Door
