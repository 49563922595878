import React  from 'react'
import "./Seg_Walls.scss";
const AssetImage =process.env.REACT_APP_ASSERT_Image

const proimages = `${AssetImage}/images/project-img.jpg`
const d1img = `${AssetImage}/images/door-pallet/d1.png`
const d2img = `${AssetImage}/images/door-pallet/d2.png`
const d3img = `${AssetImage}/images/door-pallet/d3.png`



const Seg_Door = () => {
  return (
   <div className='edit_page_section'>
    <div className='row m-0'>
      <div className='col-7 creimg'>
      <div className='proimg'>
          <img className='rounded-3  overflow-hidden' src={proimages} alt="proimg" />
      </div>
        </div>

        <div className='col-5 segpallet'>

          <div className='segcon py-4'>
           <h1>Edit Roof</h1>
           <button type="button" className="rounded-pill btn brand_btn btn-outline-dark btn-lg fs-6 mt-3"> Asphalt Shingle  <i className="bi bi-chevron-down "></i> </button>
           </div>
           
           <div className='seg_pallet'>
            <div className='row '>

            <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d1img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d1img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d1img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d1img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d2img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d2img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d2img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d2img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           <div className='pallet col-3'>
           <img className='rounded-3  overflow-hidden' src={d3img} alt="proimg" />
           <h6 className='mt-2'>Rustic Cream</h6>
           </div>

           
           </div>
           </div>

           <div className='btnclose'>
           <a role="button" className="btn btn-primary rounded-pill" href="/generate-image/edit">Close</a>
     
           </div>
        </div>
        </div>
    </div>
  
  )
}

export default Seg_Door
