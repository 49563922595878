import React from 'react'
import Seg_Walls from '../../module/segment/Seg_Walls'
// import Segments_Walls from '../../module/segment/Segment_HomePage'

const Segment_walls = () => {
  return (
    <div>
     <Seg_Walls/>
    </div>
  )
}

export default Segment_walls
