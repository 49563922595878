import React from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { addSvgOption } from '../../../../slice/svdImageSlice/SvgImageSlice'
import { startLoading } from '../../../../slice/loading/LoadingSlice'


type Props={
    isOptionModal:boolean
    onClose:()=>void
    chooseOption:(data:string)=>void
  
}
const OptionModal = ({isOptionModal,onClose,chooseOption}:Props) => {
    
    const dispatch= useDispatch()
    const handleReactCv=()=>{
    //  reactCV()
    // dispatch(addSvgOption({
    //   isOpenModal:false,
    //   svgModelName:"reactSvg"
    // }))

      chooseOption("reactSvg")
    }

    const handleOpenCv=()=>{
      
        chooseOption("openCv")
        
    }

    const handleonClose=()=>{
      onClose()
    }
  return (
    <>
     <Modal
        show={isOptionModal}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <h4 className='m-0'>Select SVG Image </h4>
        </Modal.Header>
        <Modal.Body className='select-svgimg'>
          <button className='btn border me-2  rounded-pill'
           onClick={handleOpenCv}
          >Open cv
          </button>

          <button className='btn border rounded-pill' 
            onClick={handleReactCv}
          >ReactSVG
          </button>

        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-tranparent border-primary text-primary ' onClick={handleonClose}>
            Close
          </button>
          {/* <button className='btn btn-primary'>Ok</button> */}
        </Modal.Footer>
      </Modal>
        
    </>
  )
}

export default OptionModal