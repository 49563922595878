// import { Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  getAllSwatchImages,
  getSelectedSegment,
  getUserJobData
} from '../../../../slice/userJobSlice/UserJobSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getAllSwatches } from '../../../../slice/materialSlice/MaterialSlice'
import { SwatchesModel } from '../../../../Model/material/MaterialModel'
import { getMasterArray } from '../../../../slice/canvas/masterArray/MasterArraySlice'
import { segmentationValue } from '../../../../Model/apiModel/APiModel'
import { JobSegmentationModel } from '../../../../Model/masterArray/MasterArrayModel'
import CallApiSwatch from './CallApiSwatch'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { addSvgImage, addSvgOption, addSvgPhotoImage, getSvgOption } from '../../../../slice/svdImageSlice/SvgImageSlice'
import { SelectedGroupModel } from '../../../../Model/svgModel/SvgModel'
import { stopLoading } from '../../../../slice/loading/LoadingSlice'
import { SegmentAnnotationModel } from '../../../../Model/replaceSwatchModel/ReplaceWindowSwatch'
import ReplaceSwatchHome from '../replaceSwatch/ReplaceSwatchHome'
import { addMessage } from '../../../../slice/messageToast/ToastSlice'
import SegmentBaseSwatch from '../segmentbasedSwatch/SegmentBaseSwatch'

const s3path= process.env.REACT_APP_S3BucketMaterial

type Props={
    allSegDetail:JobSegmentationModel[]
}


const SwatchImages = () => {
    const [svgPoints, setSvgPoints] = useState<number[]>([]); // Points for the polygon
    const [palletUrl, setPalletUrl] = useState<string|null>(null); // Base64 string for the image
   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
   
    const getSelectedSegments= useSelector(getSelectedSegment)
    const dispatch= useDispatch()
    const path =`${s3path}/liv/materials`;
    const getMasterArrays= useSelector(getMasterArray)
    const [ allSwatchImages, setAllSwatchImage]= useState<SwatchesModel[]>([])

    const [selctedSwatch, setSelectedSwatch]= useState< SwatchesModel|null>()
    const [isApi, setIsApi]= useState<boolean>(false)
   
    const getAllSwatchImagess= useSelector(getAllSwatchImages)
    const [groupSegmentData, setGroupSegmentData]= useState<JobSegmentationModel[]>([])

    const [projectId, setProjectId]= useState<number|null>(null)
    const getSvgOptions= useSelector(getSvgOption)
    // selected segement

    useEffect(()=>{
     
        if(getSelectedSegments && getSelectedSegments.length>0){

            setGroupSegmentData(getSelectedSegments)
        }else{
            setGroupSegmentData([])
        }
      },[getSelectedSegments])

 



    // getAll swatch images

  

  // getAll swatch images

  useEffect(() => {
    if (getAllSwatchImagess && getAllSwatchImagess.length > 0) {
       
      setAllSwatchImage(getAllSwatchImagess)
    }
  }, [getAllSwatchImagess])


//   }
  const handleSwatch=(data:SwatchesModel)=>{
    
   setSelectedSwatch(data)
    const swatchUrl=`${path}/${data.photo}`

   setPalletUrl(swatchUrl)
   if(groupSegmentData && groupSegmentData.length==0){
    dispatch(addMessage({
      isShow:true,
      mess:"Please select the segment",
       toastType:"Error"
     }))
     // alert('Please select the segment')
   }else {
    dispatch(addSvgOption({
      isOpenModal:true,
      svgModelName:""
     }))
   }
   
  
  }
  const handleSwatchFeature=(data:SwatchesModel)=>{
    
   setSelectedSwatch(data)
    const swatchUrl=data.bucket_path??""
   setPalletUrl(swatchUrl)
   if(groupSegmentData && groupSegmentData.length==0){
    
    dispatch(addMessage({
      isShow:true,
      mess:"Please select the segment",
       toastType:"Error"
     }))
     // alert('Please select the segment')
   }else {
    dispatch(addSvgOption({
      isOpenModal:true,
      svgModelName:""
     }))
   }
   
  
  }
 
  // React svg
useEffect(()=>{

  if(getSvgOptions.svgModelName==="reactSvg" && 
    groupSegmentData && 
    groupSegmentData.length>0 && palletUrl){
    reactSvg(groupSegmentData,palletUrl)
  }

  
},[getSvgOptions,groupSegmentData,palletUrl])

// open opencv
useEffect(()=>{

  if(getSvgOptions.svgModelName==="openCv" &&
   !getSvgOptions.isOpenModal &&!isApi &&
    palletUrl){
        console.log("setIsApi is true")
        setIsApi(true)
      }
     
  
  
},[getSvgOptions,palletUrl, isApi])


const reactSvg=(groupSegmentData:JobSegmentationModel[],swatchUrl:string)=>{
  const allSelected:SelectedGroupModel[]=[]
  if(groupSegmentData && groupSegmentData.length>0 && swatchUrl){
          groupSegmentData.map(item=>{
                  if(item.segName && item.details?.annotation && item.details.bb_annotation_int){
                      const data={
                          segname:item.segName,
                          annoatation:item.details?.annotation ??[],
                          coOrdinate:item.details?.bb_annotation_int??[]
                      }
                      allSelected.push(data)
                  }
          })
       
    if(allSelected && allSelected.length>0){
      dispatch(addSvgImage({
          swatchUrl:swatchUrl,
          selectedGroup:allSelected
         }))
    }
         
     }
}



  const handleShow = (data:SwatchesModel) =>{

   setShow(true);
    setSelectedSwatch(data)
    const allSelected:SelectedGroupModel[]=[]
     //const swatchUrl=data?.photo_svg
    const swatchUrl=`${path}/${data.photo}`;
    setPalletUrl(swatchUrl)
  } 

 const[ coOrdinate, setCoordinate]= useState<number[]>([])

// set svg selected seg  point
 



  const handleResetSwatch=(mess:boolean)=>{
    setSelectedSwatch(null)
    setPalletUrl(null)
    setIsApi(false)
    dispatch(stopLoading())
    if (mess) {

      console.log("swatch updated")
      // alert('Swatch Image applied ')
    } else if (!mess) {
    
      // alert('Error on swatch image')
      dispatch(addMessage({
        isShow:true,
        mess:"Error on swatch image",
         toastType:"Error"
       }))
    }
  }

  
  return (
    <>
    {/* get all swatch image based on segment */}
     <SegmentBaseSwatch/>

       {allSwatchImages &&
        allSwatchImages.length > 0 &&
        allSwatchImages.map((item) => (
          <div className="col-4" key={item.id}>
            
            <div className="palletsbox">
              <div   >
                {item.is_featured?
                (<img src={`${item.bucket_path}`} alt="swatch" 
                  onClick={() => handleSwatchFeature(item)}
                />):(
                   
                  <img src={`${path}/${item?.photo}`} alt="swatch" 
                  onClick={() => handleSwatch(item)}
                  />
                )}
            
              
              <svg
                className="checkicon md"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                ></circle>
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                ></path>
              </svg>
              </div>
            

              {/* Info icon with onClick event */}
              <span
                role="button"
                className="pallets-info"
                onClick={() => handleShow(item)} // Show Offcanvas
              >
                <i className="bi bi-info"></i>
              </span>
            </div>
            
          </div>
         
        ))}
         <button className='btn btn-primary'>All Materials Loaded</button>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement='bottom'
        className='bottom_side_new h-auto'
      >
        <Offcanvas.Header closeButton>
          {selctedSwatch  && (
            <Offcanvas.Title className='text-center'>
              {selctedSwatch.title}
            </Offcanvas.Title>
          ) }
          {/* <Offcanvas.Title className='text-center'>Offcanvas</Offcanvas.Title> */}
        </Offcanvas.Header>
        <Offcanvas.Body>
          {(selctedSwatch  && palletUrl)? (
            <div className='row mb-4'>
              <div className='col-12 col-md-6 m-auto'>
                <div className='row'>
                  <div className='col-12 col-md-4'>
                    <img
                      className='rounded-3'
                      src={palletUrl}
                      alt='Selected Swatch'
                    />
                  </div>
                  <div className='col-12 col-md-1 d-flex align-items-center justify-content-center'>
                    <span className='h-100 border border-1'></span>
                  </div>
                  <div className='col-12 col-md-7'>
                    <h4>
                      Category :
                      <span>
                        {selctedSwatch.style_brand_category?.brand?.title ||
                          'N/A'}
                      </span>
                    </h4>
                    <h4>
                      Brand :
                      <span>
                        {selctedSwatch.style_brand_category?.brand?.title ||
                          'N/A'}
                      </span>
                    </h4>
                    <h4>
                      Style :
                      <span>
                        {selctedSwatch.style_brand_category?.brand?.title ||
                          'N/A'}
                      </span>
                    </h4>
                    <h4>
                      id :
                      <span>
                        {selctedSwatch.id ||
                          'N/A'}
                      </span>
                    </h4>
                    <p>Model Size: Model Here</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>Select a swatch to see details.</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      {/* {isApi &&palletUrl && (
        <CallApiSwatch
        palletUrl={palletUrl}
        groupSegmentData={groupSegmentData}
        resetOpenCv={handleResetSwatchApi}
        />
      )} */}

      {/* Replace Swatch Home */}

     { palletUrl!=null &&
     isApi &&
     selctedSwatch  &&
     <ReplaceSwatchHome
     selctedSwatch={selctedSwatch}
      palletUrl={palletUrl}
      resetSwatch={handleResetSwatch}
      />}
    </>
  )
}

export default SwatchImages
