import React from 'react'
import {  Modal } from 'react-bootstrap'

type Props={
    isShow:boolean,
    onClose:()=>void
    ok:()=>void
    segShort:string
}
const NewSegmentModal = ({isShow,segShort,onClose,ok}:Props) => {

    const handleClose=()=>{
        onClose()
    }

    const handleOk=()=>{
      ok()
    }
  return (
    <>
          <Modal
        show={isShow}
        onHide={onClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <h5 className='model-title'>Segment Name :{segShort}</h5>
        </Modal.Header>
        <Modal.Body>
           <p className='fs-5 '> Do you want to save annotation?</p>
        </Modal.Body>
        <Modal.Footer>
        <button className='btn btn-primary' onClick={handleOk}
         >Yes</button>

          <button className='btn btn-tranparent border-primary text-primary' onClick={handleClose}>
            Close
          </button>
         
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default NewSegmentModal