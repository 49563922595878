import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { addMaterial, getMaterial } from '../../slice/materialSlice/MaterialSlice'
import { GetAllMaterial } from '../../api/material/swatch/SwatchApi'

const GetMaterials = () => {
   const dispatch= useDispatch()

  

    const getMaterials= useSelector(getMaterial)
    useEffect(()=>{
        if(getMaterials && getMaterials.length==0){
          getAllMaterials()
        }
    },[getMaterials])

    
    const getAllMaterials= async()=>{
        try{
     
             const response= await GetAllMaterial();
             
                 if(response && response.status===200){
                  dispatch(addMaterial(response.data.materials))
                 }
              // console.log("resposne", response)
        }catch(err){
           console.log("err in getting all material")
        }

    }
  return (
    <div>

    </div>
  )
}

export default GetMaterials