import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux'
import "../../../page/canvas/canvasview.scss"
import {  getUserJobBase64 } from '../../../slice/userJobSlice/UserJobSlice';
import { changeSegmentTab, getSwitchCanvas, getTabControl } from '../../../slice/tabControl/TabControlSlice';
import { JobSegmentModel, Segment } from '../../../Model/Job/JobModel';
import { PointModel } from '../../../Model/masterArray/MasterArrayModel';
import { getSegregateSegment } from '../../../slice/segegratedSlice/SegregatedSlice';

interface PointAnnotation {
  x: number,
  y: number
}

interface CustomGroupOptions extends fabric.IGroupOptions {
  groupName?: string;
  subGroupName?: string;
  childGroupName?: string // Example for an object
}

const OutLineCanvas = () => {

  const canvasRef = useRef<fabric.Canvas | null>(null);
  const canvasElementRefs = useRef<HTMLCanvasElement | null>(null);
  const rightSectionRef = useRef<HTMLDivElement | null>(null);

  const getUserJobBase64s = useSelector(getUserJobBase64);
  const [scaleX, setScalex] = useState<number | null | undefined>();
  const [scaleY, setScaley] = useState<number | null | undefined>();
  const getSegregateSegments = useSelector(getSegregateSegment);
  const [annotationPoint, setAnnotationPoint] = useState<PointAnnotation[]>([]);
  const isCanvas = useRef(true);

  const dispatch = useDispatch();
  const getTabControls = useSelector(getTabControl);
  const getSwitchCanvass = useSelector(getSwitchCanvas);

  // Clean up canvas on unmount
  useEffect(() => {
    return () => {
      if (canvasRef.current) {
        canvasRef.current.dispose();
        canvasRef.current = null;
      }
    };
  }, []);

  // Handle canvas disposal on switch
  useEffect(() => {
    if (getSwitchCanvass === "segment" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('segment'));
    } 
    else if (getSwitchCanvass === "dimension" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('dimension'));
    }
     else if (getSwitchCanvass === "compare" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('compare'));
    }
    else if (getSwitchCanvass === "genAiImage" && canvasRef.current) {
      canvasRef.current.dispose();
      canvasRef.current = null;  // Clear the reference after disposal
      dispatch(changeSegmentTab('genAiImage'));
    }
  }, [getSwitchCanvass, dispatch]);

  // Initialize the canvas when the tab is set to "outline"
  useEffect(() => {
    if (getTabControls === "outline" && 
      !canvasRef.current && 
      canvasElementRefs.current) {
      isCanvas.current = true;
      generateCanvas();
    }
  }, [getTabControls, canvasRef]);

  const generateCanvas = () => {
    const rightSection = rightSectionRef.current;
    const canvas = new fabric.Canvas(canvasElementRefs.current, {
      width: rightSection == null ? window.innerWidth : window.innerWidth - rightSection.offsetWidth,
      height: window.innerHeight,
      backgroundColor: '#f0f0f0'
    
    });
    // (canvas as any).retinaScaling = 2;
    canvas.requestRenderAll();
    canvasRef.current = canvas;
  }

  // Load the background image based on the job base64
  useEffect(() => {
    if (getUserJobBase64s && canvasRef.current && getTabControls === "outline") {
      const image = getUserJobBase64s;
      const encodedImage = "data:image/png;base64," + image;
      // const encodedImage = image;
      const decodedImage = atob(encodedImage.split(",")[1]);
      const uint8Array = new Uint8Array(decodedImage.length);

      for (let i = 0; i < decodedImage.length; i++) {
        uint8Array[i] = decodedImage.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: "image/png" });
      const imageUrl = URL.createObjectURL(blob);

      fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
        const canvas = canvasRef.current;
        if (canvas && canvas.width && canvas.height && img.width && img.height) {
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          img.scaleToWidth(canvasWidth);
          img.scaleToHeight(canvasHeight);

          canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
            scaleX: canvasWidth / img.width,
            scaleY: canvasHeight / img.height,
            originX: 'left',
            originY: 'top',
          });

          setScalex(canvasWidth / img.width);
          setScaley(canvasHeight / img.height);
          canvas.requestRenderAll();
        }
      });
    }
  }, [getUserJobBase64s, getTabControls]);

  // Handle Segregate Segments and draw polygons
  useEffect(() => {
    if (canvasRef.current && getSegregateSegments && getSegregateSegments.length > 0) {
      getSegregateSegments.forEach((segmentModel: JobSegmentModel) => {
        Object.keys(segmentModel).forEach((key) => {
          const jobDetail = segmentModel[key] as Segment;
          const annotation = jobDetail?.details?.annotation;
          const cordinate = jobDetail?.details?.bb_annotation_int;
          const segName = key;
          const groupName = jobDetail?.details?.seg_type ?? "";
          const subGroupName = jobDetail?.details?.group ?? "";
          const childName = jobDetail?.details?.seg_short ?? "";
          if (annotation && cordinate && segName) {
            collectPoints(annotation, segName, cordinate, groupName, subGroupName, childName);
          }
        });
      });
    }
  }, [canvasRef.current, getSegregateSegments, scaleX, scaleY]);

  const collectPoints = (annotation: number[], segName: string, coordinates: number[], groupName: string, subGroupName: string, childName: string) => {
    if (annotation && scaleX && scaleY) {
      const points: PointModel[] = [];
      let i;
      for (i = 0; i < annotation.length; i += 2) {
        const x = annotation[i] * scaleX;
        const y = annotation[i + 1] * scaleY;
        points.push({ x, y });
      }
      if (points && points.length > 0) {
        makePolygon(points, coordinates, segName, groupName, subGroupName, childName);
      }
    }
  }

  const makePolygon = (points: PointModel[], coordinates: number[], polyName: string, groupName: string, subGroupName: string, childName: string) => {
    if (points && points.length > 0 && coordinates && polyName && scaleX && scaleY && canvasRef.current) {
      const text = new fabric.Text(polyName, {
        left: coordinates[0] * scaleX,
        top: coordinates[1] * scaleY,
        fontFamily: 'Arial',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        fontSize: 18,
        fill: 'yellow',
        selectable: true,
        visible: false,
      });

      const polygon = new fabric.Polygon(points, {
        left: Math.round(coordinates[0] * scaleX),  // Rounding to prevent subpixel rendering
        top: Math.round(coordinates[1] * scaleY),
        fill: "transparent",
        originX: "left",
        originY: "top",
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        stroke: "rgb(7 239 253)",
       
        strokeWidth: 2,
        opacity: 10,
        lockMovementX: true,
        lockMovementY: true,
      });

      const options: CustomGroupOptions = {
        selectable: false,
        lockMovementX: true,
        lockMovementY: true,
        hasRotatingPoint: false,
        hasBorders: false,
        hasControls: false,
        groupName: groupName,
        subGroupName: subGroupName,
        childGroupName: childName,
        subTargetCheck: true,
        name: polyName
      };

      const group = new fabric.Group([polygon, text], options);
      canvasRef.current.add(group);
      canvasRef.current.requestRenderAll();
    }
  }

  return (
    <>
      {/* <div ref={rightSectionRef} className="canvas-right"></div> */}
      <canvas ref={canvasElementRefs} />
    </>
  )
}

export default OutLineCanvas;