import React from 'react'
import DemoTableHome from '../../module/demotable/DemoTableHome'

const DemoTablePage = () => {
  return (
    <div>
        <DemoTableHome/>
    </div>
  )
}

export default DemoTablePage