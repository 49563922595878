import React from 'react'

const DemoTableHome = () => {
  return (
    <div>
          <h1>hello demo table </h1>
    </div>
  )
}

export default DemoTableHome