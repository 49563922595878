import React, { useState, useRef, useEffect } from "react";
// import { TabList, TabPanels, TabPanel, Tooltip } from "@chakra-ui/react";
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "./LeftSections.scss"
import Segments from "./segments/Segments";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserLogin } from "../../../slice/user/UserSlice";
import { userModel } from "../../../Model/user/UserModel";
import { resetMedia } from "../../../slice/media/MediaSlice";
import { resetSVGImage, resetSvgImage } from "../../../slice/svdImageSlice/SvgImageSlice";
import ListView from "../ListView/ListView";
import { resetIsRegenaration, resetUploadMessage } from "../../../slice/modelData/ModelDataSlice";
import { resetJobData, resetSelectedSegment, resetUserJob } from "../../../slice/userJobSlice/UserJobSlice";
import { resetMasterArray } from "../../../slice/canvas/masterArray/MasterArraySlice";
import UpdateJobHome from "../updateJob/UpdateJobHome";
import { resetCanvasControl } from "../../../slice/canvas/masterArray/CanvasControlSlice";
import GenAiHome from "../genAi/GenAiHome";
import { changeSegmentTab, resetSegmentTab, switchToOutline } from "../../../slice/tabControl/TabControlSlice";
import { resetDimension } from "../../../slice/canvas/masterArray/DimensionSlice";
import { resetSwatchOnCanvas } from "../../../slice/canvas/masterArray/SwatchOnCanvas";
import AiExteriors from "../exteriorsAi/AiExteriors";
import { stopLoading } from "../../../slice/loading/LoadingSlice";
import { resetGenAiAttributes, resetPrompValue } from "../../../slice/genAi/GenAiAttributesSlice";
import { resetSegregated } from "../../../slice/segegratedSlice/SegregatedSlice";
import SegmentBaseSwatch from "./segmentbasedSwatch/SegmentBaseSwatch";





const LeftSections = () => {
  const [activeCombo, setActiveCombo]= useState(false)

    const Tab1 = () => <div>Content for Tab 1</div>;
    const Tab2 = () => <div>Content for Tab 2</div>;
    const Tab3 = () => <div>Content for Tab 3</div>;
    
    const [activeTab, setActiveTab] = useState(0);
    const swiperRef = useRef<SwiperClass | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);

    const handleTabClick = (index:number) => {
        setActiveTab(index);
        if (swiperRef.current) {
        swiperRef.current?.slideTo(index);
        }
    };


  const [isLogin, setIsLogin] = useState<boolean>(false)

  const dispatch = useDispatch()
  const navigator = useNavigate()
  const getUserLogins = useSelector(getUserLogin) as userModel[]
  const [userRole, setUserRole]= useState<string|null>(null)
  useEffect(() => {
    if (getUserLogins && getUserLogins.length > 0) {
      setIsLogin(true)
      if(getUserLogins[0].role){
        setUserRole(getUserLogins[0].role)
      }
    } else {
      setIsLogin(false)
    }
  }, [getUserLogins])

 
 
  const handleCustomerProfile=()=>{
      dispatch(resetSvgImage())
      removeProjectFromLocaStorage()
    navigator(`/${userRole}/project`);
  }

  const removeProjectFromLocaStorage=()=>{
    dispatch(resetSvgImage())
    removeCurrentProject()
    localStorage.removeItem("getSegregateSegments")
    localStorage.removeItem("getUserJobDatas")
    localStorage.removeItem("getMasterArrays")
    dispatch(resetUploadMessage())
    dispatch(resetIsRegenaration())
     dispatch(resetMasterArray())
    dispatch(resetSelectedSegment()) 
    dispatch(resetUserJob())
    dispatch(resetSegmentTab())
    dispatch(resetDimension())   // reset the dimension
    dispatch(resetSwatchOnCanvas())  // remove te swatch which shown on canvas
    dispatch(stopLoading())
  }

  const removeCurrentProject=()=>{
    localStorage.removeItem("getSegregateSegments") // remove segerated data
    localStorage.removeItem("getUserJobBase64s") // remove base64 image
    localStorage.removeItem("getMasterArrays") // remove masterArray
    localStorage.removeItem("getUserJobDatas")
    localStorage.removeItem("getMasterArrays")
    dispatch(resetUploadMessage())
    dispatch(resetIsRegenaration())
    dispatch(resetPrompValue()) // delte prompt
    dispatch(resetGenAiAttributes())//
  
    dispatch(resetSegregated()) // delete from segreafted segment
    dispatch(resetSelectedSegment()) // reset the selected segments
    dispatch(resetSVGImage())  // delete SV image Slice 
  }


  const handleGenAi=()=>{
    // dispatch(switchToOutline('genAiImage'))
  }

  const handlePromptGenAi=()=>{
    dispatch(switchToOutline('genAiImage'))
  }
  const handleSegment=()=>{
    dispatch(switchToOutline('segment'))
  }

  return (
    <>
        <div className="canvas-left-header">

            <div className='backtohome p-3 d-flex align-items-center justify-content-between border-bottom'>
                <span className='logo'>
                    <img src="/img/logo-white.svg" alt="" />
                </span>
                <h5 className='m-0'>Canvas View</h5>
                <i className="bi bi-arrow-left-short fs-4 cursor-pointer"
                onClick={handleCustomerProfile}
                ></i>
            </div>

            <div className='p-0 bg-white border-bottom'>
                <ul className="nav nav-underline nav-fill" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation"  onClick={handleSegment}>
                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Segments</Tooltip>}>
                            <button className="nav-link active" id="segments-tab" data-bs-toggle="tab" data-bs-target="#segments" type="button" role="tab" aria-controls="segments" aria-selected="true">SEGMENTS</button>
                        </OverlayTrigger>
                    </li>
                    <li className="nav-item" role="presentation">
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Structure</Tooltip>}>
                        <button className="nav-link" id="old-segments-tab " data-bs-toggle="tab" data-bs-target="#old-segments" type="button" role="tab" aria-controls="old-segments" aria-selected="false">
                            <i className="bi bi-grid"></i>
                        </button>
                        </OverlayTrigger>
                    </li>
                    <li className="nav-item" role="presentation" onClick={handleGenAi}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">GenAI</Tooltip>}>
                        <button className="nav-link" id="customer-view-tab" data-bs-toggle="tab" data-bs-target="#customer-view" type="button" role="tab" aria-controls="customer-view" aria-selected="false">
                            <i className="bi bi-card-heading"></i>
                        </button>
                        </OverlayTrigger>

                    </li>
                    <li className="nav-item" role="presentation" onClick={handlePromptGenAi}>
                        <button className="nav-link" id="combo-tab" data-bs-toggle="tab" data-bs-target="#combo" type="button" role="tab" aria-controls="combo" aria-selected="false">
                            <i className="bi bi-columns-gap list-icon"></i>
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div className="canvas-left-body">
            <div className="tab-content">
                <div className="tab-pane active" id="segments" role="tabpanel" aria-labelledby="segments-tab">
                    <Segments />
                </div>

                <div className="tab-pane" id="old-segments" role="tabpanel" aria-labelledby="old-segments-tab">
                    {/* <ListViewHome/> */}

                 <ListView/>

                </div>
                <div className="tab-pane" id="customer-view" role="tabpanel" aria-labelledby="customer-view-tab">
                  
                  <GenAiHome/>
                  </div>
                <div className="tab-pane" id="combo" role="tabpanel" aria-labelledby="combo-tab">
                  <AiExteriors/>
                </div>
            </div>
        </div>

            <UpdateJobHome/>
         
    </>
  );
};

export default LeftSections;
