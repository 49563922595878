import React from 'react'
import Seg_Roofs from '../../module/segment/Seg_Roofs'

const Segment_Roof = () => {
  return (
    <div>
      <Seg_Roofs/>
    </div>
  )
}

export default Segment_Roof
