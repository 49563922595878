import React, { useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import ReAnnoatationModal from '../modal/ReAnnoatationModal';
import { getCanvasSize } from '../../../../slice/userScreenSlice/userScreenResolution';
import {  getSelectedSegment, getUserJobBase64 } from '../../../../slice/userJobSlice/UserJobSlice';
import { getMasterArray, getReAnnotationSegment, updateAnnotationPoint } from '../../../../slice/canvas/masterArray/MasterArraySlice';
import MagnifierCanvas from './MagnifierCanvas';
import { PointModel } from '../../../../Model/masterArray/MasterArrayModel';
import "./Mangnifier.css"
import { startLoading, stopLoading } from '../../../../slice/loading/LoadingSlice';
import { StartUpateMasterArrayModel } from '../../../../Model/masterArray/UpdateIndexModel';
import { changeSegmentTab, switchToOutline } from '../../../../slice/tabControl/TabControlSlice';
import { getSegregateSegment, updateAnnoatationInSegegtated } from '../../../../slice/segegratedSlice/SegregatedSlice';
import AnnotationUpdateApi from '../../masterDataAnotationApi/AnnotationUpdateApi';
import { SegmentDetails } from '../../../../Model/Job/SamModel';
interface CustomCircleOptions extends fabric.ICircleOptions {
  id?: number;
  className?: string;
}
interface CustomLineOptions extends fabric.ILineOptions {
  id?: number;
  className?: string;
}

interface CustomFabricObject extends fabric.Object {
  id?: string | number;
}
const ReAnnotationCanvas = () => {
  const navigate = useNavigate()
    const canvasRef = useRef<fabric.Canvas|null>(null);
    const canvasElementRef = useRef<HTMLCanvasElement | null>(null);
    const magnifierRef = useRef<HTMLDivElement|null>(null);
    const magnifierLensRef = useRef<HTMLDivElement | null>(null)
    const magnifierContentRef = useRef<HTMLCanvasElement | null>(null);
    
    const getUserJobBase64s = useSelector(getUserJobBase64);
    const [scaleX, setScalex] = useState<number|null|undefined>();
    const [scaleY, setScaley] = useState<number|null|undefined>();
   
    const getCanvasSizes = useSelector(getCanvasSize);
    const getMasterArrays= useSelector(getMasterArray)
    const getSegregateSegments= useSelector(getSegregateSegment)
    const getReAnnotationSegments= useSelector(getReAnnotationSegment)
    const [ReAnnoationSegName, setReAnotationSegName]= useState<string|null>(null)
    const [groupSegName, setGroupSegName]= useState<string|null>(null)
    const [allpoints, setAllPoints]= useState<number[]>([])
    const [isShowDistance, setIsShowDistance]= useState<boolean>(false)
    const [mousePoint, setMousePoint]= useState<number[]>([])
    const [zoomFactor, setZoomFactor] = useState(2); // Control zoom level
    const [zoomPosition, setZoomPosition] = useState<PointModel>(); // Position of the zoom on hover
    const [segName, setsegName]= useState<string|null>(null)
    const [segShortName, setSegShortName]= useState<string|null>(null)
    const [groupName, setGroupName]= useState<string|null>(null)
    const [childGroupName, setChildGroupName]= useState<string|null>(null)
  const getSelectedSegments = useSelector(getSelectedSegment)
    const isApi= useRef(true)
    let polygonMode: boolean = true;
    const dispatch= useDispatch()
    let pointArray: { x: number; y: number }[] = []
    let lineArray: fabric.Line[] =new Array()
    //let activeLine: fabric.Line | undefined|null;
    let activeLine: fabric.Line | null = null; 
    const lineArrays: fabric.Line[] = [];
    const pointArrays: fabric.Circle[] = [];
    let hoverCircle: fabric.Circle | null = null;
    let activeShape: fabric.Polygon | undefined = undefined;
    const [jobId, setJobId]= useState<number|null>(null)
   
    const[ isShowModal, setIsShowModal]= useState<boolean>(false)
    const [ isUpdatemasterdata, setIsUpdatemasterdata]= useState<boolean>(false)
    const [annotation, setAnnotation]= useState<number[]>([])
  


    let min = 99;
    let max = 999999;
    
   
      useEffect(()=>{
        if(getSelectedSegments && getSelectedSegments.length>0){
          const selected=getSelectedSegments[0]
           const grpseg=selected.details
           if(grpseg &&
            grpseg.seg_short &&
            grpseg.label &&
            grpseg.seg_type &&
            grpseg.group
          ){
            setSegShortName(grpseg.seg_short)
            setsegName(grpseg.label)
            setGroupName(grpseg.seg_type)
            setChildGroupName(grpseg.group)
           }
        }
      },[getSelectedSegments])

      //  useEffect(()=>{
   
      //   if(getReAnnotationSegments &&
      //      getReAnnotationSegments.groupName &&
      //      getReAnnotationSegments.segName
          
      //     ){
      //     setReAnotationSegName( getReAnnotationSegments.segName)
      //     setGroupSegName(getReAnnotationSegments.groupName)
      //   }
      //  },[getReAnnotationSegments])

   
    // Add and configure canvas
  
    const isCanvas= useRef(true)
    useEffect(() => {
      if (canvasElementRef.current && isCanvas.current) {
        isCanvas.current= false
        // Initialize Fabric canvas
        const rightSection = document.querySelector('.canvas-right') as HTMLElement;
        const canvas = new fabric.Canvas(canvasElementRef.current, {
          width:rightSection==null? window.innerWidth: window.innerWidth - rightSection.offsetWidth, // Adjust width
          height: window.innerHeight,
          backgroundColor: '#f0f0f0', // Canvas background
        });

        const dimension = new fabric.Group([], { 
          selectable: false, 
          name: 'dimension',
          visible: true ,
          hasBorders: false,
            hasControls: false,
        });
        canvas.add(dimension);
      canvas.requestRenderAll();
        canvasRef.current = canvas;
        canvas.on('mouse:down', (event) => {
          // console.log("event",event)
          handleMouseDown(event);
        });
        
        canvas.on('mouse:up',
          (event) => {
            handleMouseUp(event);
         });
         canvas.on('mouse:move', (event) => {
          handleMouseMove(event); // Add mouse move event to show the dynamic point/line
        });
         canvas.on('mouse:over', (event) => {
          handleMouseOver(event); // Add mouse move event to show the dynamic point/line
        });

        canvas.on('mouse:wheel',
          (event) => {
            //handleMouseWheel(event);
         });
        //  canvas.on('mouse:out', (event) => {
        //   handleMouseOut(event); // Add mouse move event to show the dynamic point/line
        // });
         
        return () => {
          // Clean up the canvas when the component unmounts
          if (canvasRef.current) {
            canvasRef.current.off('mouse:down', handleMouseDown);
            canvasRef.current.off('mouse:up', handleMouseUp);
            canvasRef.current.off('mouse:move', handleMouseMove);
          }
        
        }
        
      } else{
        isCanvas.current= true
      }
    }, []);
    

   useEffect(()=>{
   
    if(mousePoint.length>0  &&
       allpoints &&
        allpoints.length==0){
       setIsShowDistance(true)
      showDistanceCordinate([],mousePoint,false)
    }else if(allpoints && allpoints.length>0 &&
      mousePoint &&mousePoint.length>0){
        const pointLength= allpoints.length
       const points=[allpoints[pointLength-2],allpoints[pointLength-1 ]]
        showDistanceCordinate(points,mousePoint,true)
    }
   },[allpoints, mousePoint, canvasRef.current, isShowDistance])

    // handle mouse move
    const handleMouseMove = (options: fabric.IEvent) => {
      const pointer = options.pointer;
      handleZoom(options)
      if(pointer){
       
        setMousePoint( [ pointer?.x??0, pointer?.y??0])
      
      }
    
     
    };
    
      const showDistanceCordinate=(allpoints:number[],mousePoint:number[],isShowDistance:boolean)=>{
     
        if (canvasRef.current && mousePoint) {
          const canvasObjects = canvasRef.current.getObjects();
          if (canvasObjects) {
            // Find the "dimension" group (if necessary)
            const matchingGroup = canvasObjects.find(grp => grp.name === "dimension") as fabric.Group | undefined;
            let existingText = canvasRef.current.getObjects().find(obj => obj.name === 'showDim') as fabric.Text | undefined;
      
            // If the text doesn't exist, create it and add it to the canvas
            if (!existingText) {
              existingText = new fabric.Text(``, {
                left: mousePoint[0] + 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                name: 'showDim',
                fontFamily: 'Arial',
                fontSize: 12,         // Smaller font size for crisp appearance
                fill: 'white',       // Text color
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black background with slight opacity
                selectable: false,  // Disable selection of the text
                evented: false,     // Ensure text is not selectable
                visible: true,
              });
      
              // Add the new text to the canvas
              canvasRef.current.add(existingText);
              canvasRef.current.requestRenderAll();
            }
      
            // Update the text content and position dynamically
            if(!isShowDistance){
              
              existingText.set({
                left: mousePoint[0]+ 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                text: `(${mousePoint[0].toFixed(2)}, ${mousePoint[1].toFixed(2)})`,  // Update the displayed coordinates
              });
            }
            else if (isShowDistance ){
              const distanceX=allpoints[0]-mousePoint[0]
              const distanceY=allpoints[1]-mousePoint[1]
              const distance = Math.sqrt(distanceX ** 2 + distanceY ** 2);
              existingText.set({
                left: mousePoint[0]+ 10,  // Adjust position to avoid cursor
                top: mousePoint[1] - 20,  // Adjust position to avoid cursor
                text: `${distance.toFixed(2)}`,  // Update the displayed coordinates
              });
            }
           
      
            // Update the canvas rendering after modifying the text
            existingText.setCoords();  // Ensure the internal coordinates are updated
      
            // Ensure the text object is brought to the front
            canvasRef.current.bringToFront(existingText);
            canvasRef.current.requestRenderAll();  // Render the canvas
          }
        }
      }
    
    
    // ad image on canavas
    useEffect(() => {
      if (getUserJobBase64s && canvasRef.current) {
        const image = getUserJobBase64s;
        const encodedImage = "data:image/png;base64," + image;
        //const encodedImage =  image;
        const decodedImage = atob(encodedImage.split(",")[1]);
        const uint8Array = new Uint8Array(decodedImage.length);
        for (let i = 0; i < decodedImage.length; i++) {
          uint8Array[i] = decodedImage.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: "image/png" });
        const imageUrl = URL.createObjectURL(blob);
  
        fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
          const canvas = canvasRef.current;
          if (canvas && canvas.width && canvas.height && img.width && img.height) {
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;
  
            img.scaleToWidth(canvasWidth);
            img.scaleToHeight(canvasHeight);
  
            canvas.setBackgroundImage(img, canvas.requestRenderAll.bind(canvas), {
              scaleX: canvasWidth / img.width,
              scaleY: canvasHeight / img.height,
              originX: 'left',
              originY: 'top',
            });
            setScalex(canvasWidth / img.width);
            setScaley(canvasHeight / img.height);
            canvas.requestRenderAll();
            // Update magnifier content
            if (magnifierContentRef.current) {
              const magnifierCanvas = magnifierContentRef.current;
              const context = magnifierCanvas?.getContext('2d');
              if (context) {
                // Draw the image into the magnifier's canvas
                context.clearRect(0, 0, magnifierCanvas.width, magnifierCanvas.height); // Clear before drawing
                context.drawImage(
                  img.getElement(),
                  0,
                  0,
                  img.width!,
                  img.height!,
                  0,
                  0,
                  magnifierCanvas.width,
                  magnifierCanvas.height
                );
              }
            }
          }
        });
      }
    }, [getUserJobBase64s]);

    const handleMouseDown = (options:fabric.IEvent) => {
       
        const target=options.target as CustomFabricObject
        if(target ){
          const tarid= target?.id ||0
          const canvasObjects = canvasRef.current?.getObjects()
          const matchingObject = canvasObjects?.find((obj :CustomFabricObject) => obj.id === target.id);
          if (matchingObject) {
          
            setIsShowModal(true)
            polygonMode=false
          }
        
        }
       
    if(polygonMode && canvasRef.current ){
      //console.log("canvasRef.current 4" )
     
       addPoint(options);
    }
      
    
    };


    const handleMouseWheel=(event:fabric.IEvent)=>{
      console.log("mouse Wheel",event)
      const deltaE = event.e as WheelEvent
      if(deltaE && canvasRef.current){
        const delta = deltaE.deltaY;
        let zoom = canvasRef.current.getZoom();
        zoom *= 0.999 ** delta; 
       
       if (zoom > 20) zoom = 20; // Set maximum zoom level
      if (zoom < 0.01) zoom = 0.01; // Set minimum zoom level
      canvasRef.current.zoomToPoint({ x: deltaE.offsetX, y: deltaE.offsetY }, zoom);
     deltaE.preventDefault();
     deltaE.stopPropagation();
      }
    
    
    }

    const handleMouseUp = (options:fabric.IEvent) => {
      // // console.log("options",options)
       console.log("mouse up")
     drawPolygon()
      
    };

    const handleMouseOver = (options: fabric.IEvent) => {
      
      // Get the pointer location
      const pointer = canvasRef.current?.getPointer(options.e);
     
      if (pointer) {
        const target = options.target as CustomFabricObject;
        const top = pointer.y;
        const left = pointer.x;
        
        if (target && canvasRef.current && top && left) {
          const targetId = target?.id || 0;
    
          // Check if the target matches an object on the canvas
          const canvasObjects = canvasRef.current.getObjects();
    
          // Find the matching object by ID
          const matchingObject = canvasObjects?.find((obj: CustomFabricObject) => obj.id === targetId);
    
          if (matchingObject) {
           
            // Check if a circle (finalpoint) already exists
            let existingCircle = canvasObjects?.find((obj: CustomFabricObject) => obj.name === "finalpoint");
    
            if (existingCircle) {
              // Move the existing circle to the new position (with some tolerance)
              const tolerance = 30; // Adjust this tolerance value as needed
              const circleTop = existingCircle.top || 0;
              const circleLeft = existingCircle.left || 0;
    
              if (Math.abs(circleTop - top) > tolerance || Math.abs(circleLeft - left) > tolerance) {
                // Update the position of the existing circle
                existingCircle.set({
                  left: left,
                  top: top
                });
                canvasRef.current.renderAll();
              }
            } else {
              // Create a new circle if none exists
              const circle = new fabric.Circle({
                radius: 10,
                fill: "transparent",
                stroke: "red",
                strokeWidth: 2,
                name: 'finalpoint',
                left: left,
                top: top,
                selectable: false,
                hasBorders: false,
                hasControls: false,
                originX: 'center',
                originY: 'center',
                visible: true
              } as CustomCircleOptions);
             
              canvasRef.current.add(circle);
              canvasRef.current.renderAll();
              canvasRef.current.hoverCursor = "none";
            }
          } else {
            // If no matching object is found, remove the existing circle if it exists
            const existingCircle = canvasObjects?.find((obj: CustomFabricObject) => obj.name === "finalpoint");
            if (existingCircle) {
              canvasRef.current.remove(existingCircle);
              canvasRef.current.renderAll();
            }

            canvasRef.current.hoverCursor = "default";
          }
        }
      }
    };
    
    
    // Handle mouse out to remove the hover circle
    const handleMouseOut = (options: fabric.IEvent) => {
 
    
      // Remove the hover circle if it exists
      if (hoverCircle && canvasRef.current) {
        canvasRef.current.remove(hoverCircle);
        hoverCircle = null; // Reset the hoverCircle
        canvasRef.current.renderAll();
      }
    };
    const drawPolygon = () => {

      polygonMode = true;
      // pointArray = new Array();
      // lineArray = new Array();
    
     
    };

    const addPoint = (options: fabric.IEvent) => {
      const random = Math.floor(Math.random() * (max - min + 1)) + min;
      const id = new Date().getTime() + random;
      const circle = new fabric.Circle({
        radius: 1,
        fill: '#000000',
        stroke: 'rgb(255, 1, 154)',
        strokeWidth: 3,
        id:id,
        className:'point',
        left: options.pointer?.x,
        top: options.pointer?.y,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
      }  as CustomCircleOptions);
  
     
      if (!circle.left || !circle.top) return;
  
      if (pointArray.length === 0) {
        circle.set({ fill: '#000000' }); // First point is black
      } else {
        circle.set({ fill: '#ff0000' }); // Other points can be a different color (e.g., red)
      }
       pointArray.push({x:circle.left,y:circle.top})

       // Add the new point as a circle on the canvas
          pointArrays.push(circle);
          canvasRef.current?.add(circle)

       const points: [number, number, number,number] = [
        circle.left,
         circle.top,
         circle.left,
         circle.top];
      
   

      if ( activeShape ) {
     
        canvasRef.current?.remove(activeShape);
        const pos = canvasRef.current?.getPointer(options.e);
        if (pos) {
      
          const points = activeShape?.get('points') ?? [];
          points.push(new fabric.Point(pos.x, pos.y)); // Use fabric.Point
          activeShape.set({ points: points });
          var polygon = new fabric.Polygon(points,{
           // stroke:'rgb(255 1 154)',
             stroke:'rgb(7 239 253)',
              strokeWidth:2,
              fill: 'transparent',
              opacity: 0.9,
              selectable: false,
              hasBorders: false,
              hasControls: false,
              evented: false
          });
         
           canvasRef.current?.add(polygon);
            
           activeShape = polygon;
        
          canvasRef.current?.renderAll();
        }
        
       
      } else {
       
        const x = options.pointer?.x ?? 0; // Ensure x is a number, use 0 if undefined
        const y = options.pointer?.y ?? 0; // Ensure y is a number, use 0 if undefined
        
        const newPolyPoints = [{ x, y }];
        const polygon = new fabric.Polygon(newPolyPoints,{
          stroke:'#333333',
          strokeWidth:1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          name:"poly"
      });
      activeShape=polygon
       canvasRef.current?.add(polygon)
      }
        // Update the line connecting to the mouse pointer
  if (activeLine) {
    canvasRef.current?.remove(activeLine);
  }

    // activeLine = line;
        // console.log("poinst",points)
        setAllPoints((prev) => [
          ...prev,           
          points[0],       
          points[1],
        ]);
      pointArrays.push(circle);
     // lineArray.push(line);
    //  canvasRef.current?.add(line);
      canvasRef.current?.add(circle);
      
      
    };
  
  
    const [isCompletedPolygon, setIsCompletedPolygon]= useState(false)



    const handleCloseModal=()=>{
      setIsShowModal(false)
      
    }

    const handleOk=()=>{

      setIsShowModal(false)
     // setAllReAnnoatationPoints.push(pointArrays)
     convertToBoundingBox(allpoints)
    }
   

 

  function convertToBoundingBox(flattenedPoints:number[]) {
    if(scaleX && scaleY){
 
     let  newFlattenedPoints=[]
      const points = flattenedPoints;
 
       
      for (let i = 0; i < points.length; i += 2) {
        const x = points[i]/(scaleX??1);
        const y = points[i + 1]/(scaleY??1);
        newFlattenedPoints.push(parseFloat(x.toFixed(2)),parseFloat(y.toFixed(2)))
       
      }
  
    
     if( newFlattenedPoints.length>0 &&
      segName &&
      childGroupName &&
      segShortName  && 
      groupName ){
        console.log("newFlattenedPoints",newFlattenedPoints)
        setIsUpdatemasterdata(true)
           setAnnotation(newFlattenedPoints)

      }
  
  }
 
}


     useEffect(()=>{
        if(getMasterArrays && getMasterArrays.jobId){
            setJobId(getMasterArrays.jobId)
        }else{
            setJobId(null)
        }
     },[getMasterArrays])
  const handlehoverLayer=()=>{

    // localStorage.setItem("getMasterArray",JSON.stringify(getMasterArray))
    // localStorage.setItem('getSegregateSegment',JSON.stringify(getSegregateSegment))
    dispatch(switchToOutline("segment"))
    dispatch(changeSegmentTab('segment'))
      //dispatch(changeCanvasMode("hoverLayer"))
  }

 // add maginier kens
 const handleZoom = (options:fabric.IEvent) => {
  if (!canvasRef.current) return;
const pointer = canvasRef.current.getPointer(options.e);

const magnifierLens = magnifierLensRef.current;
const magnifierContent = magnifierContentRef.current;

if (magnifierLens && magnifierContent ) {

  
magnifierLens.style.display = 'block';
const lensSize = 5; // size of the magnifier lens
const lens = 100; // size of the magnifier lens
magnifierLens.style.left = `${lensSize}px`;
magnifierLens.style.top = `${lensSize}px`;

magnifierContent.style.left = `-${pointer.x/2 }px`;
magnifierContent.style.top = `-${pointer.y/2}px`;

// Draw the cursor on the magnifier content
const lensContext = magnifierContent.getContext('2d');
console.log('magnifierContent',magnifierContentRef)

}  
}

const handleResetAnotation=(data:SegmentDetails)=>{
  setAnnotation([])
  setIsUpdatemasterdata(false)
  dispatch(stopLoading())

  const masterValue={
    segName:segName,
    childgroupName:childGroupName,
    seg_short:segShortName,
    groupName:groupName,
    masterData:data
  }
  
  dispatch(startLoading())
  // update in segretaed  Array
  dispatch(updateAnnoatationInSegegtated(masterValue))
     
      dispatch(switchToOutline("segment"))
       dispatch(changeSegmentTab('segment'))
}
const handleFailResetAnnotation=()=>{
  setAnnotation([])
  setIsUpdatemasterdata(false)
  dispatch(stopLoading())

  dispatch(switchToOutline("segment"))
  dispatch(changeSegmentTab('segment'))
}
  return (
    < >
    <div className='position-relative'>
    <div className='re-anno-section'  onClick={handlehoverLayer}>
      <button className=' d-flex  align-items-center btn btn-secondary border-0 fs-5'> 
      <span className='fs-4'>
      <i className="bi bi-arrow-left-short pe-1"></i>
      </span>
        
        {getReAnnotationSegments.name}-{getReAnnotationSegments.segName}
   </button> 
      
      </div>
     {/* magnifier canvas */}
  
   {/* <MagnifierCanvas
    // zoomPosition={zoomPosition}
    // zoomFactor={zoomFactor}
   /> */}
    {/* Magnifier Section */}
    <div className="magnifier-lens" ref={magnifierLensRef}>
        <canvas className="magnifier-content " ref={magnifierContentRef} ></canvas>
      </div>
    <canvas  ref={canvasElementRef} className='dzi-van' ></canvas>
       
       
       
      </div>
    
     
     <ReAnnoatationModal
       isShow={isShowModal}
       onClose={handleCloseModal}
       ok={handleOk}
     />


{isUpdatemasterdata &&
annotation &&
childGroupName &&
<AnnotationUpdateApi
segmentationInt={annotation}
segName={childGroupName}
resetAnnotation={handleResetAnotation}
resetFailAnnotation={handleFailResetAnnotation}
/>}
    
  </>
  )
}

export default ReAnnotationCanvas