import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice'
import { DragHandleIcon } from '@chakra-ui/icons'
import WebSocketGenAi from './WebSocketGenAi'
import GenAiImages from './GenAiImages'

const urlpath= process.env.REACT_APP_Project_Image
const GenAiHome = () => {
  const getMasterArrays= useSelector(getMasterArray)
  const [imagePath, setImagePath]= useState<string|null>(null)
  const [isOpenSocket, setIsOpenSocket]= useState<boolean>(false)
  
 


  // get project image path
  useEffect(()=>{
    if(getMasterArrays && getMasterArrays.image && getMasterArrays.project_id){
      const imgPath=`${urlpath}/${getMasterArrays.project_id}/${getMasterArrays.image}`
      setImagePath(imgPath)
      
    }
  },[getMasterArrays])
  const handleGenAiStyles=()=>{
    setIsOpenSocket(true)

  }
  return (
    <>

      {/* web socket */}
   {imagePath && 
    <WebSocketGenAi
    imagePath={imagePath}
    />}
    
     <GenAiImages/>

    
    
  
    </>
   
  )
}

export default GenAiHome