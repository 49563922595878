import React, { useEffect, useRef, useState } from 'react'
import { Box, FormControl, FormLabel, Input, useDisclosure } from "@chakra-ui/react";
import "./AiExteriors.scss"
import imageicon from "../../../../public/img/fileselect.svg"
import {
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    SliderMark,
} from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux';
import { getGenAiAttributes, getGenAiImage, getPromptValues, startGenAiImage } from '../../../slice/genAi/GenAiAttributesSlice';
import { GenAiAttributesModel, OptionJDetails, PromptModel } from '../../../Model/genAiAttributes/GenAiAttributes';
import { Tab, Tabs } from 'react-bootstrap';
import GenAiFeatures from './tabs/GenAiFeatures';
import GenAiSegments from './tabs/GenAiSegments';
import GetPrompt from './getPrompt/GetPrompt';
import PromptToAiImage from './promptToAiImage/PromptToAIImage';
import { getMasterArray } from '../../../slice/canvas/masterArray/MasterArraySlice';
import GetGenAiImage from '../genAi/GetGenAiImage';
import GenAiImages from '../genAi/GenAiImages';
import GenAiGallery from './tabs/GenAiGallery';



const s3BucketPath= process.env.REACT_APP_S3Bucket

const AiExteriors = () => {
    const [thumbnailUrl, setThumbnailUrl] = useState<string>("");
    const fileInputRef = useRef<HTMLInputElement>(null)
    const getGenAiAttributess = useSelector(getGenAiAttributes) as GenAiAttributesModel[]
    const [allGenAttributes, setAllGenAttributes] = useState<GenAiAttributesModel>()
    const [promptData, setPromptData] = useState<string | null>(null)
    const [allGenAttrFeature, setAllGenAttrFeature] = useState<GenAiAttributesModel>()
    const [allGenAttrSegments, setAllGenAttrSegments] = useState<GenAiAttributesModel>()
    const [selectedDropdownValues, setSelectedDropdownValues] = useState<PromptModel>({});
    const getPromptValuess = useSelector(getPromptValues)
    const getMasterArrays= useSelector(getMasterArray)
    const [imagePath, setImagePath]= useState<string|null>(null)
    const [isgen, setIsGen]= useState<boolean>(false)
    const [isGenImage, setIsGenImage]= useState<boolean>(false)
    const[tabSelector, setTabSelector]= useState<string|null>(null)
    const dispatch= useDispatch()
    const getGenAiImages=useSelector(getGenAiImage)
    useEffect(()=>{
        if(getMasterArrays &&
             getMasterArrays.image && 
             getMasterArrays.project_id){
            const  image=`${s3BucketPath}/projects/${getMasterArrays.project_id}/${getMasterArrays.image}`;
            setImagePath(image)
           
        }
    },[getMasterArrays])


    // control GenAi images
    useEffect(()=>{
        if(getGenAiImages){
            setIsGenImage(true)
        }else{
            setIsGenImage(false)
        }
    },[getGenAiImages])

    useEffect(() => {
        if (getGenAiAttributess && getGenAiAttributess.length > 0) {
            console.log("getGenAiAttributess[0]", getGenAiAttributess[0])
            setAllGenAttributes(getGenAiAttributess[0])
            const allAtt = getGenAiAttributess[0]
            if (allAtt) {
                (Object.keys(allAtt) as Array<keyof GenAiAttributesModel>).forEach(gen => {
                    const values = allAtt[gen]
                    if (values && values.type) {
                        if (values.type === "feature") {
                            setAllGenAttrFeature((prev) => ({
                                ...prev,
                                [gen]: values
                            }))
                        }
                        else if (values.type === "segment") {
                            setAllGenAttrSegments((prev) => ({
                                ...prev,
                                [gen]: values
                            }))
                        }
                    }
                })

            }
        }
    }, [getGenAiAttributess])
    const handleDragImage = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    useEffect(() => {
        if (getPromptValuess) {
            setSelectedDropdownValues(getPromptValuess)
        }
    }, [getPromptValuess])

    const handlePromptResponse = (data: string) => {
        setPromptData(data)
    }


     // start Ai generation images
    const handleGenAiImage=()=>{
        setIsGen(true)
        dispatch(startGenAiImage())
    }
       
        const handleResetGenAi=()=>{
            setIsGen(false)
        }

        const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setPromptData(event.target.value); 
        };

        const handleGallery=()=>{
            console.log(";helll")
            setTabSelector("gallery")
        }

        const handleProfile=()=>{
            console.log(";helll")
            setTabSelector("profile")
        }

        const handleHome=()=>{
            console.log(";helll")
            setTabSelector("home")
        }
    return (
        <>
            <div className=' ai-exteriors-sec'>

                <div className='aiexter-custo-sec  py-2'>

                <Tabs
  defaultActiveKey="home"
  id="fill-tab-example"
  className="mb-3"
  fill
>
  <Tab eventKey="home" title={<span className="tab">Features</span>} onClick={handleHome}>
    {allGenAttrFeature && Object.keys(allGenAttrFeature).length > 0 && (
      <GenAiFeatures allGenAttrFeature={allGenAttrFeature} />
    )}
  </Tab>

  <Tab eventKey="profile" title={<span className="tab">Segments</span>} onClick={handleProfile}>
    {allGenAttrSegments && Object.keys(allGenAttrSegments).length > 0 && (
      <GenAiSegments allGenAttrSegments={allGenAttrSegments} />
    )}
  </Tab>

  <Tab eventKey="longer-tab" title={<span className="tab">Gallery</span>} onClick={handleGallery}>
    <GenAiGallery />
  </Tab>
</Tabs>

                </div>


              
               <div className='gen-layout px-4'>
                    <div className='pt-3'>
                        <h6 className='fw-medium '>
                            Custom AI Instructions
                            <span data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="Information about house angles.">
                                <i className="bi bi-question-circle ps-1"></i>
                            </span>
                        </h6>
                        <textarea className="form-control from-textarea"
                         id="exampleFormControlTextarea1" rows={3}
                            value={promptData != null ? promptData : ""}
                            onChange={handleInputChange}
                        ></textarea>
                        {/*  */}
                    </div>

                    <div className='d-flex gap-1 mt-2'>
                        <span className='switch-checkbox'>Private Gallery</span>
                        <div className="form-check form-switch ms-2">
                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        </div>
                        <span className='switch-checkbox'>Public Gallery</span>
                    </div>
                    <button className='btn btn-primary rounded-pill create-btn mt-2 w-100 '
                    onClick= {handleGenAiImage}
                    >Generate with Ai</button>
                    {/* inactive btn class "inactive" */}
                </div> 
         
            </div>



            {selectedDropdownValues &&
                Object.keys(selectedDropdownValues).length > 0 &&
                <GetPrompt

                    selectedDropdownValues={selectedDropdownValues}
                    resetPrompt={handlePromptResponse}
                />}


                 {/* starting generation Ai images */}
               {imagePath &&
               promptData &&
               isgen &&
                <PromptToAiImage
                promptData={promptData}
                imagePath={imagePath}
                resetImagePath={handleResetGenAi}
                />}

                {/*  get all Gen Ai Images form Folder */}
               {isGenImage &&
                <GetGenAiImage/>}
                
        </>

    )
}

export default AiExteriors
