
import axios from "axios";
import { MaterialCategoryModel } from "../../../Model/material/MaterialCategoryModel";
import { MaterialModel } from "../../../Model/material/MaterialModel";

// const serverApi = import.meta.env.SERVER_URL
const serverApi =process.env.REACT_APP_SERVER_URL

const databaserServer="http://localhost:5000"

// getAll maetrial catorgyies
export function GetAllMaterial() { 
    return axios.get(`${serverApi}/material-library/swatches`)
        .then(response => {
            return response
        }).catch(error => {
            console.log("error material",error)
            throw error;
        });


}

// update 
export function UpdateSwatch(data:MaterialModel){
    return axios.post(`${serverApi}/material-library/swatch/update`,data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// add
export function AddSwatch(data:MaterialModel){
    return axios.post(`${serverApi}/material-library/swatch/add`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}

// delete
export function DeleteSwatch(id:number){
    const data={
        id:id
    }
    return axios.post(`${serverApi}/material-library/swatch/delete`, data)
    .then(response => {
        return response
    }).catch(error => {
        throw error;
    });

}
